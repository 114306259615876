/* eslint-disable unicorn/prefer-ternary */
import produce, { Draft } from 'immer';

import {
  MetaType,
  MultimediaItem,
  TalentItem,
} from '@utils/sdk/types/api-types';
import {
  listTalents,
  retrieveTalent,
  retrieveTalentMultimedia,
  retrieveTalentDedicationHighlights,
  listTalentsSearchParameters,
} from './actions';

export type TalentState = {
  error: string | null;
  profile: {
    item: TalentItem | null;
    loading: boolean;
    multimedia: MultimediaItem[];
  };
  list: {
    loading: boolean;
    items: TalentItem[];
    meta: MetaType | null;
    search?: {
      name?: string;
      country?: string;
      category?: string;
      service?: string;
      nameOrder?: 'ASC' | 'DESC';
      customSearch?: string;
      page?: number;
      limit?: number;
    };
  };
  dedications: {
    loading: boolean;
    items: TalentItem[];
  };
};

const initialState: TalentState = {
  error: null,
  profile: {
    item: null,
    loading: false,
    multimedia: [],
  },
  list: {
    items: [],
    loading: false,
    meta: null,
    search: {},
  },
  dedications: {
    items: [],
    loading: false,
  },
};

const reducer = produce((draft: Draft<TalentState>, { type, payload }) => {
  switch (type) {
    // RETRIEVE / MULTIMEDIA
    case retrieveTalent.TRIGGER:
      draft.profile.loading = true;
      draft.error = null;
      break;
    case retrieveTalent.SUCCESS:
      draft.profile.item = payload;
      break;
    case retrieveTalentMultimedia.SUCCESS:
      draft.profile.multimedia = payload;
      break;
    case retrieveTalent.FAILURE:
      draft.error = payload;
      draft.profile.item = null;
      break;
    case retrieveTalent.FULFILL:
      draft.profile.loading = false;
      break;
    // LIST
    case listTalents.TRIGGER:
      draft.list.loading = true;
      draft.error = null;
      break;
    case listTalents.SUCCESS:
      draft.list.items = payload.items;
      draft.list.meta = payload.meta;
      break;
    case listTalents.FAILURE:
      draft.error = payload;
      draft.list.items = [];
      draft.list.meta = null;
      break;
    case listTalents.FULFILL:
      draft.list.loading = false;
      break;
    // DEDICATIONS HIGHLIGHTS
    case retrieveTalentDedicationHighlights.TRIGGER:
      draft.dedications.loading = true;
      draft.error = null;
      break;
    case retrieveTalentDedicationHighlights.SUCCESS:
      draft.dedications.items = payload;
      break;
    case retrieveTalentDedicationHighlights.FAILURE:
      draft.error = payload;
      draft.dedications.items = [];
      break;
    case retrieveTalentDedicationHighlights.FULFILL:
      draft.dedications.loading = false;
      break;

    // LIST SEARCH PARAMETERS
    case listTalentsSearchParameters.toString():
      draft.list.search = { ...draft.list.search, ...payload };
  }
}, initialState);

export default reducer;
