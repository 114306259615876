import { StrictEffect, call, put, takeLatest } from 'redux-saga/effects';
import SDK from '@utils/sdk';
import { ReduxFormPayload } from 'redux-saga-routines';
import { Action } from 'redux-actions';
import {
  bookingRequest,
  retrieveBooking,
  retrieveBookingServices,
  retrieveBookingStatus,
} from './actions';

export function* retrieveBookingServicesSaga(): Generator<StrictEffect> {
  try {
    yield put(retrieveBookingServices.request());

    const api: any = yield call([SDK, 'getApi']);
    const { body }: any = yield call([
      api['booking-services'],
      'bookingServiceList',
    ]);
    yield put(retrieveBookingServices.success(body));
  } catch (error) {
    yield put(retrieveBookingServices.failure(error));
  } finally {
    yield put(retrieveBookingServices.fulfill());
  }
}

export function* bookingRequestSaga({
  payload: { values },
}: Action<ReduxFormPayload<any, any>>): Generator<StrictEffect> {
  try {
    yield put(bookingRequest.request());
    const requestBody = {
      ...values,
      contact: {
        ...values.contact,
        phoneNumber: values.contact.phoneNumber.phone,
      },
      capacityService: values.capacityService
        ? Number(values.capacityService)
        : undefined,
    };
    const api: any = yield call([SDK, 'getApi']);
    yield call([api.bookings, 'bookingCreate'], {}, { requestBody });
    yield put(bookingRequest.success());
  } catch (error) {
    yield put(bookingRequest.failure(error));
  } finally {
    yield put(bookingRequest.fulfill());
  }
}

export function* retrieveBookingSaga({
  payload,
}: Action<{ id: string }>): Generator<StrictEffect> {
  try {
    yield put(retrieveBooking.request());
    const api: any = yield call([SDK, 'getApi']);
    const { body }: any = yield call([api.bookings, 'bookingById'], payload);
    yield put(retrieveBooking.success(body));
  } catch (error) {
    yield put(retrieveBooking.failure(error));
  } finally {
    yield put(retrieveBooking.fulfill());
  }
}

export function* retrieveBookingStatusSaga(): Generator<StrictEffect> {
  try {
    yield put(retrieveBookingStatus.request());
    const api: any = yield call([SDK, 'getApi']);
    const { body }: any = yield call([
      api['booking-status'],
      'bookingStatusList',
    ]);
    yield put(retrieveBookingStatus.success(body));
  } catch (error) {
    yield put(retrieveBookingStatus.failure(error));
  } finally {
    yield put(retrieveBookingStatus.fulfill());
  }
}

export default function* bookingWatch() {
  yield takeLatest(
    retrieveBookingServices.TRIGGER,
    retrieveBookingServicesSaga,
  );
  yield takeLatest(bookingRequest.TRIGGER, bookingRequestSaga);
  yield takeLatest(retrieveBooking.TRIGGER, retrieveBookingSaga);
  yield takeLatest(retrieveBookingStatus.TRIGGER, retrieveBookingStatusSaga);
}
