/* eslint-disable sonarjs/no-collapsible-if */
/* eslint-disable unicorn/no-lonely-if */
/* eslint-disable no-prototype-builtins */
/* eslint-disable unicorn/filename-case */
// eslint-disable unicorn/no-lonely-if
const validateNonEmpty = (object: Record<string, any>): boolean => {
  for (const property in object) {
    if (object.hasOwnProperty(property)) {
      if (!object[property]) {
        return false;
      }
    }
  }
  return true;
};

export default validateNonEmpty;
