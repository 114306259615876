/* eslint-disable unicorn/no-array-for-each */
/* eslint-disable arrow-body-style */
/* eslint-disable unicorn/prevent-abbreviations */
// @ts-nocheck
const listenForOutsideClicks = (
  listening,
  setListening,
  menuRef,
  setIsOpen,
) => {
  return () => {
    if (listening) return;
    if (!menuRef.current) return;
    setListening(true);
    [`click`, `touchstart`].forEach((type) => {
      document.addEventListener(`click`, (evt) => {
        if (menuRef.current.contains(evt.target)) return;
        setIsOpen(false);
      });
    });
  };
};

export default listenForOutsideClicks;
