/* eslint-disable camelcase */
import {
  put,
  takeLatest,
  call,
  StrictEffect,
  select,
  takeEvery,
  debounce,
  cancel,
} from 'redux-saga/effects';
import { Action } from 'redux-actions';
import { ReduxFormPayload } from 'redux-saga-routines';
import { push } from 'connected-react-router';
import { SubmissionError } from 'redux-form';
import ReactGA from 'react-ga';
import queryString from 'query-string';

import { SDK } from '@utils/sdk';
import { getIsUserComplete, getUserItem } from '@modules/user/selectors';
import { retrieveUser } from '@modules/user/actions';
import { getFilters } from '@modules/nav/selectors';
import {
  authCheck,
  authForgotPassword,
  authLogin,
  authLoginApple,
  authLoginGoogle,
  authLogout,
  authRegister,
  clearSession,
  debounceRefresh,
  refreshToken,
  retryActions,
  authCheckPasswordCode,
  authRecoverPassword,
  authChangePassword,
} from './actions';
import { getAuthRefreshToken, getRetryActions } from './selectors';

export function* authCheckSaga({
  payload: { values: requestBody },
}: Action<ReduxFormPayload<any, any>>): Generator<StrictEffect> {
  try {
    yield put(authCheck.request());
    const urlParametres: any = yield select(getFilters);
    const urlLogin = queryString.stringifyUrl(
      {
        url: '/login',
        query: urlParametres,
      },
      { skipEmptyString: true, skipNull: true },
    );
    const urlRegister = queryString.stringifyUrl(
      {
        url: '/register',
        query: urlParametres,
      },
      { skipEmptyString: true, skipNull: true },
    );

    Object.assign(requestBody, {
      userType: 'People',
    });
    const api: any = yield call([SDK, 'getApi']);
    const { body }: any = yield call(
      [api.auth, 'loginCheck'],
      {},
      { requestBody },
    );

    if (body.user) {
      yield put(retrieveUser.success(body.user));
      yield put(push(urlLogin));
    } else {
      yield put(retrieveUser.success({ email: requestBody.email }));
      yield put(push(urlRegister));
    }
    yield put(authCheck.success(body));
  } catch (error: any) {
    yield put(
      authCheck.failure(
        new SubmissionError({ _error: error?.response?.body?.message }),
      ),
    );
  } finally {
    yield put(authCheck.fulfill());
  }
}

export function* authRegisterSaga({
  payload: { values: requestBody },
}: Action<ReduxFormPayload<any, any>>): Generator<StrictEffect> {
  try {
    yield put(authRegister.request());
    const api: any = yield call([SDK, 'getApi']);
    yield call([api.people, 'peopleSignIn'], {}, { requestBody });
    yield put(authRegister.success());
    const { email, password } = requestBody;
    yield put(authLogin({ values: { email, password } }));
  } catch (error) {
    yield put(authRegister.failure(error));
  } finally {
    yield put(authRegister.fulfill());
  }
}

export function* authLoginSaga({
  payload: { values: requestBody },
}: Action<ReduxFormPayload<any, any>>): Generator<StrictEffect> {
  try {
    yield put(authLogin.request());
    Object.assign(requestBody, {
      userType: 'People',
    });
    const urlParametres: any = yield select(getFilters);
    const api: any = yield call([SDK, 'getApi']);
    const { body }: any = yield call([api.auth, 'login'], {}, { requestBody });
    yield call([SDK, 'setToken'], body.accessToken);
    yield put(retrieveUser.success(body.user));
    yield put(authLogin.success(body));
    yield call([ReactGA, 'set'], { userId: body.user.id });

    const isComplete = yield select(getIsUserComplete);
    let redirect = '/';
    if (urlParametres?.fromUrl) {
      redirect = queryString.stringifyUrl(
        {
          url: urlParametres.fromUrl,
          query: urlParametres,
        },
        { skipEmptyString: true, skipNull: true },
      );
    }

    const urlRegister = queryString.stringifyUrl(
      {
        url: '/register-completion',
        query: urlParametres,
      },
      { skipEmptyString: true, skipNull: true },
    );
    yield isComplete ? put(push(redirect)) : put(push(urlRegister));
  } catch (error: any) {
    yield put(
      authLogin.failure(
        new SubmissionError({ password: error?.response?.body?.message }),
      ),
    );
  } finally {
    yield put(authLogin.fulfill());
  }
}

export function* authLoginGoogleSaga({
  payload,
}: Action<string>): Generator<StrictEffect> {
  try {
    yield put(authLoginGoogle.request());
    const urlParametres: any = yield select(getFilters);

    const api: any = yield call([SDK, 'getApi']);
    const { body }: any = yield call(
      [api.auth, 'loginGoogle'],
      {},
      {
        requestBody: {
          token: payload,
          userType: 'People',
        },
      },
    );
    yield call([SDK, 'setToken'], body.accessToken);
    yield put(retrieveUser.success(body.user));
    yield put(authLoginGoogle.success(body));
    yield call([ReactGA, 'set'], { userId: body.user.id });

    const isComplete = yield select(getIsUserComplete);
    let redirect = '/';
    if (urlParametres?.fromUrl) {
      redirect = queryString.stringifyUrl(
        {
          url: urlParametres.fromUrl,
          query: urlParametres,
        },
        { skipEmptyString: true, skipNull: true },
      );
    }
    const urlRegister = queryString.stringifyUrl(
      {
        url: '/register-completion',
        query: urlParametres,
      },
      { skipEmptyString: true, skipNull: true },
    );
    yield isComplete ? put(push(redirect)) : put(push(urlRegister));
  } catch (error) {
    yield put(authLoginGoogle.failure(error));
  } finally {
    yield put(authLoginGoogle.fulfill());
  }
}

export function* authLoginAppleSaga({
  payload,
}: Action<Record<string, any>>): Generator<StrictEffect> {
  try {
    const { id_token } = payload;
    yield put(authLoginApple.request());
    const urlParametres: any = yield select(getFilters);
    const api: any = yield call([SDK, 'getApi']);
    const { body }: any = yield call(
      [api.auth, 'loginApple'],
      {},
      {
        requestBody: {
          code: id_token,
          userType: 'People',
        },
      },
    );
    yield call([SDK, 'setToken'], body.accessToken);
    yield put(retrieveUser.success(body.user));
    yield put(authLoginApple.success(body));
    yield call([ReactGA, 'set'], { userId: body.user.id });

    const isComplete = yield select(getIsUserComplete);
    let redirect = '/';
    if (urlParametres?.fromUrl) {
      redirect = queryString.stringifyUrl(
        {
          url: urlParametres.fromUrl,
          query: urlParametres,
        },
        { skipEmptyString: true, skipNull: true },
      );
    }
    const urlRegister = queryString.stringifyUrl(
      {
        url: '/register-completion',
        query: urlParametres,
      },
      { skipEmptyString: true, skipNull: true },
    );
    yield isComplete ? put(push(redirect)) : put(push(urlRegister));
  } catch (error) {
    yield put(authLoginApple.failure(error));
  } finally {
    yield put(authLoginApple.fulfill());
  }
}

export function* authLogoutSaga(): Generator<StrictEffect> {
  try {
    yield put(authLogout.request());
    const user: any = yield select(getUserItem);
    const api: any = yield call([SDK, 'getApi']);
    yield call(
      [api.auth, 'logout'],
      {},
      {
        requestBody: { id: user.id, userType: 'People' },
      },
    );
    yield call([SDK, 'setToken'], null);
    yield put(authLogout.success());
    yield put(clearSession());
    yield call([ReactGA, 'set'], { userId: null });
    yield put(push('/'));
  } catch (error) {
    yield put(authLogout.success());
    yield put(authLogout.failure(error));
    yield put(clearSession());
  } finally {
    yield put(authLogout.fulfill());
  }
}

export function* authChangePasswordSaga({
  payload: { values: requestBody },
}: Action<ReduxFormPayload<any, any>>): Generator<StrictEffect> {
  try {
    yield put(authChangePassword.request());
    Object.assign(requestBody, {
      userType: 'People',
    });
    const api: any = yield call([SDK, 'getApi']);
    yield call(
      [api.auth, 'changePassword'],
      {},
      {
        requestBody,
      },
    );
    yield put(authChangePassword.success());
  } catch (error) {
    yield put(authChangePassword.failure(error));
  } finally {
    yield put(authChangePassword.fulfill());
  }
}

export function* authForgotPasswordSaga({
  payload: { values: requestBody },
}: Action<ReduxFormPayload<any, any>>): Generator<StrictEffect> {
  try {
    yield put(authForgotPassword.request());
    Object.assign(requestBody, {
      userType: 'People',
    });
    const api: any = yield call([SDK, 'getApi']);
    const { body }: any = yield call(
      [api.auth, 'forgotPassword'],
      { mobile: false },
      { requestBody },
    );
    yield put(authForgotPassword.success(body));
    yield put(push('/login'));
  } catch (error) {
    yield put(authForgotPassword.failure(error));
  } finally {
    yield put(authForgotPassword.fulfill());
  }
}

export function* authVerificationCodeSaga({
  payload: { code = null, email = null },
}: Action<{
  code: string | null;
  email: string | null;
}>): Generator<StrictEffect> {
  try {
    if (!code || !email) {
      yield put(push('/'));
      yield cancel();
    }
    const requestBody = { code, email, userType: 'People' };
    yield put(authCheckPasswordCode.request());
    const api: any = yield call([SDK, 'getApi']);
    const { body }: any = yield call(
      [api.auth, 'verificationCode'],
      {},
      { requestBody },
    );
    yield put(authCheckPasswordCode.success(body));
  } catch (error: any) {
    yield put(authCheckPasswordCode.failure(error));
    if (error.response.status === 401) {
      yield put(push('/'));
      yield cancel();
    }
  } finally {
    yield put(authCheckPasswordCode.fulfill());
  }
}

export function* authRecoverPasswordSaga({
  payload: { values: requestBody },
}: Action<ReduxFormPayload<any, any>>): Generator<StrictEffect> {
  try {
    yield put(authRecoverPassword.request());
    Object.assign(requestBody, {
      userType: 'People',
    });
    const filters: any = yield select(getFilters);
    const api: any = yield call([SDK, 'getApi']);
    yield call(
      [api.auth, 'resetPassword'],
      { code: filters.code, email: filters.email },
      { requestBody },
    );
    yield put(authRecoverPassword.success());
  } catch (error) {
    yield put(authRecoverPassword.failure(error));
  } finally {
    yield put(authRecoverPassword.fulfill());
  }
}

export function* refreshTokenSaga(): Generator<StrictEffect> {
  try {
    yield put(refreshToken.request());
    const refresh: any = yield select(getAuthRefreshToken);
    const api: any = yield call([SDK, 'getApi']);
    const { body }: any = yield call(
      [api.auth, 'refreshToken'],
      {},
      {
        requestBody: { refreshToken: refresh },
      },
    );
    SDK.setToken(body.accessToken);
    yield put(refreshToken.success(body));
    yield put(retryActions());
  } catch (error: any) {
    yield put(authLogout());
    yield put(refreshToken.failure(error));
  } finally {
    yield put(refreshToken.fulfill());
  }
}

export function* debounceRefreshSaga({
  payload: { error, type, payload = null },
}: Action<any>): Generator<StrictEffect> {
  try {
    yield put(debounceRefresh.request());
    if (error?.status === 401) {
      yield put(debounceRefresh.success({ type, payload }));
      yield put(refreshToken());
    }
  } catch (error_: any) {
    yield put(debounceRefresh.failure(error_));
  } finally {
    yield put(debounceRefresh.fulfill());
  }
}

export function* retryActionsSaga(): Generator<StrictEffect> {
  try {
    yield put(retryActions.request());
    const actions: any = yield select(getRetryActions) || [];
    for (const action of actions) {
      const { type, payload } = action;
      yield put({ type, payload });
      yield put(retryActions.success());
    }
  } catch (error) {
    yield put(retryActions.failure(error));
  } finally {
    yield put(retryActions.fulfill());
  }
}

export default function* authWatch(): Generator<StrictEffect> {
  yield takeLatest(authCheck.TRIGGER, authCheckSaga);
  yield takeLatest(authRegister.TRIGGER, authRegisterSaga);
  yield takeLatest(authLogin.TRIGGER, authLoginSaga);
  yield takeLatest(authLoginGoogle.TRIGGER, authLoginGoogleSaga);
  yield takeLatest(authLoginApple.TRIGGER, authLoginAppleSaga);
  yield takeLatest(authLogout.TRIGGER, authLogoutSaga);
  yield takeLatest(authForgotPassword.TRIGGER, authForgotPasswordSaga);
  yield takeLatest(authRecoverPassword.TRIGGER, authRecoverPasswordSaga);
  yield takeLatest(authCheckPasswordCode.TRIGGER, authVerificationCodeSaga);
  yield takeLatest(retryActions.TRIGGER, retryActionsSaga);
  yield takeEvery(debounceRefresh.TRIGGER, debounceRefreshSaga);
  yield debounce(500, refreshToken.TRIGGER, refreshTokenSaga);
}
