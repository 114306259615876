import { createAction } from 'redux-actions';
import { bindRoutineToReduxForm, createRoutine } from 'redux-saga-routines';

import {
  AUTH_CHECK,
  AUTH_REGISTER,
  AUTH_LOGIN,
  AUTH_LOGOUT,
  AUTH_CLEAR_SESSION,
  AUTH_DEBOUNCE_REFRESH,
  AUTH_REFRESH_TOKEN,
  AUTH_RETRY_ACTIONS,
  AUTH_LOGIN_GOOGLE,
  AUTH_LOGIN_APPLE,
  AUTH_FORGOT_PASSWORD,
  AUTH_RECOVER_PASSWORD,
  AUTH_CHECK_PASSWORD_CODE,
  AUTH_CHANGE_PASSWORD,
} from './types';

export const authCheck = createRoutine(AUTH_CHECK);
export const authRegister = createRoutine(AUTH_REGISTER);
export const authLogin = createRoutine(AUTH_LOGIN);
export const authLoginGoogle = createRoutine(AUTH_LOGIN_GOOGLE);
export const authLoginApple = createRoutine(AUTH_LOGIN_APPLE);
export const authLogout = createRoutine(AUTH_LOGOUT);
export const authForgotPassword = createRoutine(AUTH_FORGOT_PASSWORD);
export const authRecoverPassword = createRoutine(AUTH_RECOVER_PASSWORD);
export const authCheckPasswordCode = createRoutine(AUTH_CHECK_PASSWORD_CODE);
export const authChangePassword = createRoutine(AUTH_CHANGE_PASSWORD);

export const refreshToken = createRoutine(AUTH_REFRESH_TOKEN);
export const debounceRefresh = createRoutine(AUTH_DEBOUNCE_REFRESH);
export const retryActions = createRoutine(AUTH_RETRY_ACTIONS);

export const bindedAuthLogin = bindRoutineToReduxForm(authLogin);
export const binedAuthRegister = bindRoutineToReduxForm(authRegister);
export const bindedAuthCheck = bindRoutineToReduxForm(authCheck);
export const bindedForgotPassword = bindRoutineToReduxForm(authForgotPassword);
export const bindedChangePassword = bindRoutineToReduxForm(authChangePassword);
export const bindedRecoverPassword =
  bindRoutineToReduxForm(authRecoverPassword);

export const clearSession = createAction(AUTH_CLEAR_SESSION);
