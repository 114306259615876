import { FC } from 'react';
import { Row, Col, Button } from 'reactstrap';
import EmptyCart from '@assets/images/empty-cart.svg';
import { useDispatch, useSelector } from 'react-redux';
import { getCartIsOpen } from '@modules/cart/selector';
import { toggleCart } from '@modules/cart/actions';
import { push } from 'connected-react-router';

const CartEmtpy: FC = () => {
  const dispatch = useDispatch();
  const isCartOpen = useSelector(getCartIsOpen);

  const handleExplore = () => {
    if (isCartOpen) {
      dispatch(toggleCart());
    }
    dispatch(push('/talents'));
  };
  return (
    <Row className="h-100 align-items-center justify-content-center">
      <Col xs={12} className="text-center">
        <img src={EmptyCart} alt="empty-cart" className="mb-3" />
        <h2 className="cart__empty--title">tu carrito está vacío</h2>
        <p className="cart__empty--description">
          ¿No te has antojado aún? ¡Revisa todas las opciones que tenemos para
          ti!
        </p>
        <Button
          onClick={handleExplore}
          type="button"
          className="ui__button ui__button--primary cart__empty--button"
        >
          Explorar
        </Button>
      </Col>
    </Row>
  );
};

export default CartEmtpy;
