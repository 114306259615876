import { FC } from 'react';
import {
  InvoiceItem,
  TalentItem,
  TalentServiceItem,
} from '@utils/sdk/types/api-types';
import { Row, Col, Label, Container, Button } from 'reactstrap';
import ReactCountryFlag from 'react-country-flag';
import { useSelector } from 'react-redux';
import {
  LazyLoadImage,
  trackWindowScroll,
  ScrollPosition,
} from 'react-lazy-load-image-component';
import { getOccasionsItems } from '@modules/general/selectors';
import ServicePriceCard from '../service-price-card';
import OcassionCard from '../occasion-card';

type CartDetailProperties = {
  talent: TalentItem;
  service: TalentServiceItem;
  invoice: InvoiceItem; // This is created in build service process
  onGoBack?: () => void;
  scrollPosition: ScrollPosition;
};

const CartDetail: FC<CartDetailProperties> = ({
  talent,
  service,
  invoice,
  onGoBack,
  scrollPosition,
}) => {
  const occassions = useSelector(getOccasionsItems);

  const {
    serviceIndications,
    serviceUrl,
    serviceWhoSend,
    serviceWhoReceives,
    serviceMentionWhoSent,
    serviceOccasion,
  } = invoice || {};

  const occasion = occassions.find(
    (occ) => occ.id === (serviceOccasion as unknown as string),
  );

  const handleGoBack = () => {
    if (onGoBack) {
      onGoBack();
    }
  };

  return (
    <div>
      <Container>
        <Row className="mb-4">
          <Col className="d-flex align-items-center px-0">
            <Button
              onClick={handleGoBack}
              className="ui__button ui__button--transparent p-0 cart__card--button-back"
            >
              <i className="icon-ic_right" />
            </Button>
            <h1 className="cart__slideout--title d-inline mb-0">DETALLE</h1>
          </Col>
        </Row>
        {/* TALENT INFO */}
        <Row>
          <Col className="flex-grow-0">
            <LazyLoadImage
              className="cart__card--image"
              src={talent?.profileImage?.url}
              alt={talent.firstName}
              scrollPosition={scrollPosition}
            />
          </Col>
          <Col className="d-flex justify-content-center flex-column flex-grow-1">
            <p className="cart__card--title">
              {talent?.firstName} {talent?.lastName}
            </p>
            <p className="cart__card--subtitle my-1">
              {talent.country && (
                <ReactCountryFlag
                  svg
                  countryCode={talent.country.prefix.toLowerCase()}
                />
              )}{' '}
              {talent.profession}
            </p>
          </Col>
        </Row>

        {/* SERVICE INFO */}
        <Row className="my-3">
          <Col xs={12}>
            <ServicePriceCard {...service} />
          </Col>
        </Row>

        {/* SERVICE DETAILS */}
        <Row>
          <Col xs={12}>
            <Row className="">
              {/* TYPE: SOCIAL */}
              {service?.service.type === 'social' && (
                <Col xs={12} className="mt-3">
                  <Label>Enlace de la publicación</Label>
                  <input
                    className="ui__form-input--login"
                    type="text"
                    value={serviceUrl}
                    disabled
                  />
                </Col>
              )}
              {/* TYPE: VIDEO */}
              {service?.service.type === 'video' && (
                <>
                  <Col xs={12} className="mt-3">
                    <Label>¿Quién recibirá el video?</Label>
                    <input
                      className="ui__form-input--login"
                      type="text"
                      value={serviceWhoReceives}
                      disabled
                    />
                  </Col>
                  <Col xs={12} className="mt-3">
                    <div className="ui__checkbox mb-3">
                      <input
                        type="checkbox"
                        checked={serviceMentionWhoSent}
                        disabled
                      />
                      <span className="ui__checkbox-check">
                        <i className="icon-ic_check_noncircle" />
                      </span>
                      <Label>Mencionar a quién envía el video</Label>
                    </div>

                    <input
                      className="ui__form-input--login"
                      type="text"
                      value={serviceWhoSend}
                      disabled
                    />
                  </Col>
                  <Col xs={12} className="mt-3">
                    {occasion && <OcassionCard {...occasion} />}
                  </Col>
                  <Col xs={12} className="mt-3">
                    <Label>Mensaje</Label>
                    <textarea
                      className="ui__form-input--login"
                      value={serviceIndications}
                      disabled
                    />
                  </Col>
                </>
              )}
              {service?.service.widget === 'comment' && (
                <Col xs={12} className="mt-3">
                  <Label>Mensaje</Label>
                  <textarea
                    className="ui__form-input--login"
                    value={serviceIndications}
                    disabled
                  />
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default trackWindowScroll(CartDetail);
