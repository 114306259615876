import { createRoutine } from 'redux-saga-routines';
import {
  RETRIEVE_CATEGORIES_HIGHLIGHTS,
  RETRIEVE_SERVICES,
  RETRIEVE_CATEGORIES,
  RETRIEVE_OCCASSIONS,
  RETRIEVE_DOCUMENT_TYPES,
} from './types';

export const retrieveServices = createRoutine(RETRIEVE_SERVICES);
export const retrieveIdentificationTypes = createRoutine(
  RETRIEVE_DOCUMENT_TYPES,
);
export const retrieveOcassions = createRoutine(RETRIEVE_OCCASSIONS);
export const retrieveCategories = createRoutine(RETRIEVE_CATEGORIES);
export const retrieveCategoriesHighlights = createRoutine(
  RETRIEVE_CATEGORIES_HIGHLIGHTS,
);
