import {
  StrictEffect,
  put,
  select,
  take,
  takeLatest,
} from 'redux-saga/effects';
import { getCartItems, getCouponItem } from '@modules/cart/selector';
import { createPendingInvoice } from '@modules/invoice/actions';
import { submitCheckout } from './actions';

function* submitCheckoutSaga(): Generator<StrictEffect> {
  try {
    yield put(submitCheckout.request());
    const cart = yield select(getCartItems);
    const coupon = yield select(getCouponItem);

    yield put(createPendingInvoice({ cart, coupon }));
    yield take(createPendingInvoice.SUCCESS);
  } catch (error) {
    yield put(submitCheckout.failure(error));
  } finally {
    yield put(submitCheckout.fulfill());
  }
}

export default function* checkoutWatch() {
  yield takeLatest(submitCheckout.TRIGGER, submitCheckoutSaga);
}
