import {
  StrictEffect,
  call,
  debounce,
  put,
  takeLatest,
} from 'redux-saga/effects';
import { Action } from 'redux-actions';

import SDK from '@utils/sdk';
import { MetaSearchRequestType } from '@utils/sdk/types/api-types';
import { debounceRefresh } from '@modules/auth/actions';
import {
  retrieveTalent,
  retrieveTalentMultimedia,
  listTalents,
  retrieveTalentDedicationHighlights,
  listTalentsSearchParameters,
} from './actions';

export function* retrieveTalentSaga({
  payload,
}: Action<string>): Generator<StrictEffect> {
  try {
    yield put(retrieveTalent.request());
    const api: any = yield call([SDK, 'getApi']);
    const { body }: any = yield call([api.talents, 'talentOne'], payload);
    yield put(retrieveTalent.success(body));
  } catch (error) {
    yield put(retrieveTalent.failure(error));
  } finally {
    yield put(retrieveTalent.fulfill());
  }
}

export function* retrieveTalentMultimediaSaga({
  payload,
}: Action<string>): Generator<StrictEffect> {
  try {
    yield put(retrieveTalentMultimedia.request());
    const api: any = yield call([SDK, 'getApi']);
    const { body }: any = yield call(
      [api.talents, 'talentMultimedia'],
      payload,
    );
    yield put(retrieveTalentMultimedia.success(body));
  } catch (error) {
    yield put(retrieveTalentMultimedia.failure(error));
  } finally {
    yield put(retrieveTalentMultimedia.fulfill());
  }
}

export function* listTalentsSaga({
  payload,
  type,
}: Action<MetaSearchRequestType>): Generator<StrictEffect> {
  try {
    yield put(listTalents.request());
    const parameters = payload;
    const api: any = yield call([SDK, 'getApi']);
    const { body }: any = yield call(
      [api.talents, 'talentSearch'],
      parameters,
      {},
    );
    yield put(listTalents.success(body));
  } catch (error) {
    yield put(listTalents.failure(error));
    yield put(debounceRefresh({ error, type, payload }));
  } finally {
    yield put(listTalents.fulfill());
  }
}

export function* retrieveTalentDedicationHighlightsSaga(): Generator<StrictEffect> {
  try {
    yield put(retrieveTalentDedicationHighlights.request());
    const api: any = yield call([SDK, 'getApi']);
    const parameters = {
      occasion: ['birthday', 'song', 'special', 'love'],
      limit: 8,
    };
    const { body }: any = yield call(
      [api.talents, 'talentListHighlightsOccasionVideos'],
      parameters,
    );
    yield put(retrieveTalentDedicationHighlights.success(body));
  } catch (error) {
    yield put(retrieveTalentDedicationHighlights.failure(error));
  } finally {
    yield put(retrieveTalentDedicationHighlights.fulfill());
  }
}

export default function* talentsWatch() {
  yield takeLatest(retrieveTalent.TRIGGER, retrieveTalentSaga);
  yield takeLatest(
    retrieveTalentMultimedia.TRIGGER,
    retrieveTalentMultimediaSaga,
  );
  yield debounce(500, listTalents.TRIGGER, listTalentsSaga);
  yield debounce(500, listTalentsSearchParameters.toString(), listTalentsSaga);
  yield takeLatest(
    retrieveTalentDedicationHighlights.TRIGGER,
    retrieveTalentDedicationHighlightsSaga,
  );
}
