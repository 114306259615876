/* eslint-disable no-case-declarations */
import produce, { Draft, Immutable } from 'immer';

import {
  authCheck,
  authLogin,
  authRegister,
  authLoginApple,
  authLoginGoogle,
  authLogout,
  authForgotPassword,
  authRecoverPassword,
  authCheckPasswordCode,
  authChangePassword,
  debounceRefresh,
  refreshToken,
  retryActions,
} from './actions';

export type AuthState = Immutable<{
  loading: boolean;
  token: string | null;
  refreshToken: string | null;
  isLoggedIn: boolean;
  error: string | Error | null;
  pendingActions: string;
}>;

const initialState: AuthState = {
  loading: false,
  error: null,
  token: null,
  refreshToken: null,
  isLoggedIn: false,
  pendingActions: '',
};

export default produce((draft: Draft<AuthState>, { type, payload }) => {
  switch (type) {
    case authLogin.REQUEST:
    case authRegister.REQUEST:
    case authCheck.REQUEST:
    case authLoginGoogle.REQUEST:
    case authLoginApple.REQUEST:
    case authLogout.REQUEST:
    case authForgotPassword.REQUEST:
    case authRecoverPassword.REQUEST:
    case authChangePassword.REQUEST:
    case authCheckPasswordCode.REQUEST:
      draft.loading = true;
      break;
    case authLogin.SUCCESS:
    case authLoginApple.SUCCESS:
    case authLoginGoogle.SUCCESS:
      draft.token = payload.accessToken;
      draft.refreshToken = payload.refreshToken;
      draft.error = null;
      draft.isLoggedIn = true;
      break;
    case authLogout.SUCCESS:
      draft.token = null;
      draft.isLoggedIn = false;
      draft.refreshToken = null;
      draft.pendingActions = '';
      break;
    case authLogin.FAILURE:
    case authRegister.FAILURE:
    case authCheck.FAILURE:
    case authLoginApple.FAILURE:
    case authLoginGoogle.FAILURE:
    case authLogout.FAILURE:
    case authForgotPassword.FAILURE:
    case authRecoverPassword.FAILURE:
    case authChangePassword.FAILURE:
    case authCheckPasswordCode.FAILURE:
      draft.error = payload;
      break;
    case authLogin.FULFILL:
    case authRegister.FULFILL:
    case authCheck.FULFILL:
    case authLoginApple.FULFILL:
    case authLoginGoogle.FULFILL:
    case authLogout.FULFILL:
    case authForgotPassword.FULFILL:
    case authRecoverPassword.FULFILL:
    case authChangePassword.FULFILL:
    case authCheckPasswordCode.FULFILL:
      draft.loading = false;
      break;
    case refreshToken.SUCCESS:
      draft.token = payload.accessToken;
      draft.refreshToken = payload.refreshToken;
      break;
    case refreshToken.FAILURE:
      draft.isLoggedIn = false;
      draft.token = null;
      draft.refreshToken = null;
      break;
    case debounceRefresh.SUCCESS:
      const { pendingActions: retry } = draft;
      const oldObjs = (retry && JSON.parse(retry)) || [];
      const updatedObjs = [...oldObjs, payload];
      draft.pendingActions = JSON.stringify(updatedObjs);
      break;
    case retryActions.SUCCESS:
      const { pendingActions } = draft;
      const objs = (pendingActions && JSON.parse(pendingActions)) || [];
      objs.shift();
      draft.pendingActions = JSON.stringify(objs);
      break;
  }
}, initialState);
