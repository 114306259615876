import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { Col } from 'reactstrap';
import {
  LazyLoadImage,
  trackWindowScroll,
  ScrollPosition,
} from 'react-lazy-load-image-component';

import { TalentItem } from '@utils/sdk/types/api-types';

type CategoryCollageProperties = {
  talents?: TalentItem[];
  scrollPosition: ScrollPosition;
};

const CategoryCollage: FC<CategoryCollageProperties> = ({
  talents,
  scrollPosition,
}) => {
  const dispatch = useDispatch();

  const goToTalent = (talentId: string) => () => {
    dispatch(push(`/talents/${talentId}`));
  };

  return (
    <>
      {talents?.slice(0, 4).map((talent) => (
        <Col
          key={talent.id}
          xs={6}
          className="px-0 cursor-pointer"
          onClick={goToTalent(talent.id)}
        >
          <LazyLoadImage
            className="popular-categories__card--img img-fluid"
            src={talent.profileImage.url}
            alt={talent.firstName}
            scrollPosition={scrollPosition}
            effect="blur"
          />
        </Col>
      ))}
    </>
  );
};
export default trackWindowScroll(CategoryCollage);
