import { FC, useState } from 'react';
import { Collapse, DropdownItem, DropdownMenu, NavItem } from 'reactstrap';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { authLogout } from '@modules/auth/actions';

const ProfileMenu: FC = () => {
  const dispatch = useDispatch();
  const [isHelpOpen, setHelpOpen] = useState(false);

  const toggleHelp = () => setHelpOpen(!isHelpOpen);

  const logout = () => {
    dispatch(authLogout());
  };
  return (
    <DropdownMenu end className="ui__navbar--dropdown py-4 px-1">
      <DropdownItem className="mb-4" to="/settings" tag={NavLink}>
        <i className="icon-ic_avatar" />
        Mi perfil
      </DropdownItem>
      <NavItem
        tag="a"
        className="mb-4 d-flex align-items-center cursor-pointer"
        onClick={toggleHelp}
      >
        <i className="icon-ic_help" />
        Ayuda
        <i
          className={classNames('ms-auto icon-ic_right', {
            rotate: isHelpOpen,
          })}
        />
      </NavItem>

      <Collapse isOpen={isHelpOpen} tag="ul" className="ps-3">
        <NavItem className="mb-4">
          <a
            href="https://web.bestfriendfamoso.com/client-support/?source=client"
            target="_blank"
            rel="noreferrer"
          >
            <i className="icon-ic_help" />
            Chat en línea
          </a>
        </NavItem>
        <NavItem className="mb-4">
          <a href="mailto:soporte@bestfriendfamoso.com">
            <i className="icon-ic_mail" />
            Escríbenos
          </a>
        </NavItem>
        <NavItem className="mb-4">
          <a href="https://intercom.help/BestFriendFamoso/es/articles/6222073-preguntas-frequentes-fans">
            <i className="icon-ic_question1" />
            Preguntas frecuentes
          </a>
        </NavItem>
      </Collapse>
      <DropdownItem
        className="d-flex align-items-center"
        onClick={logout}
        toggle={false}
      >
        <i className="icon-ic_sign" />
        Cerrar sesión
      </DropdownItem>
    </DropdownMenu>
  );
};

export default ProfileMenu;
