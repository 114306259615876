import { StrictEffect, call, put, takeLatest } from 'redux-saga/effects';
import { Action } from 'redux-actions';
import SDK from '@utils/sdk';
import { locationCities, locationCountries, locationStates } from './actions';

export function* locationCountriesSaga({
  payload,
}: Action<{ search: string }>): Generator<StrictEffect> {
  try {
    yield put(locationCountries.request());
    const { search } = payload;
    const api: any = yield call([SDK, 'getApi']);
    const { body }: any = yield call([api.location, 'getCountries'], {
      name: search,
    });
    yield put(locationCountries.success(body));
  } catch (error) {
    yield put(locationCountries.failure(error));
  } finally {
    yield put(locationCountries.fulfill());
  }
}
export function* locationStatesSaga({
  payload,
}: Action<{ search: string; parent: string }>): Generator<StrictEffect> {
  try {
    yield put(locationStates.request());
    const { search, parent } = payload;
    const api: any = yield call([SDK, 'getApi']);
    const { body }: any = yield call([api.location, 'getStates'], {
      countryId: parent,
      stateName: search,
    });
    yield put(locationStates.success(body));
  } catch (error) {
    yield put(locationStates.failure(error));
  } finally {
    yield put(locationStates.fulfill());
  }
}

export function* locationCitiesSaga({
  payload,
}: Action<{ search: string; parent: string }>): Generator<StrictEffect> {
  try {
    yield put(locationCities.request());
    const { search, parent } = payload;
    const api: any = yield call([SDK, 'getApi']);
    const { body }: any = yield call([api.location, 'getCities'], {
      stateId: parent,
      cityName: search,
    });
    yield put(locationCities.success(body));
  } catch (error) {
    yield put(locationCities.failure(error));
  } finally {
    yield put(locationCities.fulfill());
  }
}
export default function* locationWatch() {
  yield takeLatest(locationCountries.TRIGGER, locationCountriesSaga);
  yield takeLatest(locationStates.TRIGGER, locationStatesSaga);
  yield takeLatest(locationCities.TRIGGER, locationCitiesSaga);
}
