import { FC } from 'react';
import { OccassionItem } from '@utils/sdk/types/api-types';
import { Col, Row } from 'reactstrap';
import {
  LazyLoadImage,
  trackWindowScroll,
  ScrollPosition,
} from 'react-lazy-load-image-component';

type OcassionCardProperties = { scrollPosition: ScrollPosition };

const OcassionCard: FC<OccassionItem & OcassionCardProperties> = ({
  color,
  image,
  name,
  scrollPosition,
}) => (
  <div className="services__price-card my-2">
    <Row className="w-100 mx-0">
      <Col className="flex-grow-0">
        <div
          className="services__price-card--icon text-center"
          style={{ backgroundColor: color }}
        >
          <LazyLoadImage
            src={image}
            alt={name}
            className="img-fluid px-1"
            scrollPosition={scrollPosition}
          />
        </div>
      </Col>
      <Col className="flex-grow-1 d-flex flex-column justify-content-center">
        <p className="services__price-card--subtitle">Ocasión</p>
        <p className="services__price-card--title">{name}</p>
      </Col>
    </Row>
  </div>
);

export default trackWindowScroll(OcassionCard);
