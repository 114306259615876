import { RootState } from '@store/types';
import validateNonEmpty from '@utils/validate-non-empty';
import { createSelector } from 'reselect';

const FINISH_STATES = new Set([
  'Cancelled',
  'Rejected',
  'Completed',
  'Finalización',
  'Cancelada',
]);

export const getUser = (state: RootState) => state.user;

export const getUserItem = createSelector(getUser, (me) => me.item);
export const getUserError = createSelector(getUser, (me) => me.error);
export const isUserLoading = createSelector(getUser, (me) => me.loading);
export const getFullName = createSelector(getUserItem, (me) =>
  `${me?.firstName || ''} ${me?.lastName || ''}`.trim(),
);
export const getUserRequests = createSelector(getUser, (me) => me.requests);

export const getRequestActive = createSelector(getUserRequests, (rèquests) =>
  rèquests.filter((request) => !FINISH_STATES.has(request.status)),
);

export const getRequestFinish = createSelector(getUserRequests, (requests) =>
  requests.filter((request) => FINISH_STATES.has(request.status)),
);

export const isRefreshingInvoinces = createSelector(
  getUser,
  (me) => me.refreshingRequest,
);
export const getCodeForgetPassword = createSelector(
  getUser,
  (me) => me.forgotPassword,
);

export const getIsUserComplete = createSelector(getUserItem, (me) => {
  const properties = {
    identificationNumber: me?.identificationNumber,
    identificationType: me?.identificationType,
    phonenumber: me?.phonenumber,
    country: me?.country,
    city: me?.city,
    state: me?.state,
    firstName: me?.firstName,
    lastName: me?.lastName,
  };
  return validateNonEmpty(properties);
});
