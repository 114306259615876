import { listTalents } from '@modules/talent/actions';
import { getTalentListItems } from '@modules/talent/selectors';
import TalentSearchCard from '@views/ui/talent-search-card';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Collapse } from 'reactstrap';
import listenForOutsideClicks from '../listen-for-outside-clicks';

const SearchTopbar = () => {
  const dispatch = useDispatch();
  const searchReference = useRef(null);
  const [listening, setListening] = useState(false);
  const results = useSelector(getTalentListItems);
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState('');

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setValue(newValue);
    dispatch(listTalents({ customSearch: newValue, name: newValue }));
  };

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(
    listenForOutsideClicks(listening, setListening, searchReference, setIsOpen),
  );

  return (
    <div className="search-topbar" ref={searchReference}>
      <div className="d-none d-md-block">
        <input
          className="form-control flex-grow-1 d-none d-md-block"
          type="search"
          value={value}
          onClick={toggle}
          onChange={handleSearch}
          placeholder="Buscar"
          aria-label="buscar"
        />
        <i className="icon-ic_search " />
      </div>

      <Button className="ui__button--transparent d-md-none" onClick={toggle}>
        <i className="icon-ic_search " />
      </Button>

      <div className="search-topbar__dropdown">
        <Collapse isOpen={isOpen} className="search-topbar__collapse p-4">
          <div className="search-topbar__collapse--input d-md-none">
            <input
              className="form-control flex-grow-1  mb-4"
              type="search"
              value={value}
              onChange={handleSearch}
              placeholder="Buscar"
              aria-label="buscar"
            />
            <i className="icon-ic_search " />
          </div>

          {results.map((result) => (
            <TalentSearchCard key={result.id} item={result} toggle={toggle} />
          ))}
        </Collapse>
      </div>
    </div>
  );
};

export default SearchTopbar;
