import { StrictEffect, call, put, takeLatest } from 'redux-saga/effects';
import SDK from '@utils/sdk';
import {
  retrieveCategories,
  retrieveCategoriesHighlights,
  retrieveIdentificationTypes,
  retrieveOcassions,
  retrieveServices,
} from './actions';

export function* retrieveServicesSaga(): Generator<StrictEffect> {
  try {
    yield put(retrieveServices.request());

    const api: any = yield call([SDK, 'getApi']);
    const { body }: any = yield call([api.services, 'serviceList'], {
      enabled: false,
    });
    yield put(retrieveServices.success(body));
  } catch (error) {
    yield put(retrieveServices.failure(error));
  } finally {
    yield put(retrieveServices.fulfill());
  }
}

export function* categoriesHighlightsSaga(): Generator<StrictEffect> {
  try {
    yield put(retrieveCategoriesHighlights.request());
    const parameters = { filter: 'Destacados' };
    const api: any = yield call([SDK, 'getApi']);
    const { body }: any = yield call(
      [api.categories, 'categoryList'],
      parameters,
    );
    yield put(retrieveCategoriesHighlights.success(body));
  } catch (error) {
    yield put(retrieveCategoriesHighlights.failure(error));
  } finally {
    yield put(retrieveCategoriesHighlights.fulfill());
  }
}

export function* retrieveCategoriesSaga(): Generator<StrictEffect> {
  try {
    yield put(retrieveCategories.request());
    const api: any = yield call([SDK, 'getApi']);
    const { body }: any = yield call([api.categories, 'categoryList']);
    yield put(retrieveCategories.success(body));
  } catch (error) {
    yield put(retrieveCategories.failure(error));
  } finally {
    yield put(retrieveCategories.fulfill());
  }
}

export function* retrieveOcassionsSaga(): Generator<StrictEffect> {
  try {
    yield put(retrieveOcassions.request());
    const api: any = yield call([SDK, 'getApi']);
    const { body }: any = yield call([api.occasions, 'occasionList']);
    yield put(retrieveOcassions.success(body));
  } catch (error) {
    yield put(retrieveOcassions.failure(error));
  } finally {
    yield put(retrieveOcassions.fulfill());
  }
}

export function* retrieveIdentificationTypesSaga(): Generator<StrictEffect> {
  try {
    yield put(retrieveIdentificationTypes.request());
    const api: any = yield call([SDK, 'getApi']);
    const { body }: any = yield call([api.location, 'getDocumentType']);
    yield put(retrieveIdentificationTypes.success(body));
  } catch (error) {
    yield put(retrieveIdentificationTypes.failure(error));
  } finally {
    yield put(retrieveIdentificationTypes.fulfill());
  }
}

export default function* generalWatch() {
  yield takeLatest(retrieveServices.TRIGGER, retrieveServicesSaga);
  yield takeLatest(retrieveOcassions.TRIGGER, retrieveOcassionsSaga);
  yield takeLatest(retrieveCategories.TRIGGER, retrieveCategoriesSaga);
  yield takeLatest(
    retrieveIdentificationTypes.TRIGGER,
    retrieveIdentificationTypesSaga,
  );
  yield takeLatest(
    retrieveCategoriesHighlights.TRIGGER,
    categoriesHighlightsSaga,
  );
}
