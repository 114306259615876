import { createRoutine } from 'redux-saga-routines';
import { createAction } from 'redux-actions';

import {
  TALENT_RETRIEVE,
  TALENT_LIST,
  TALENT_HIGHLIGHTS_DEDICATIONS,
  TALENT_LIST_SEARCH_PARAMS,
  TALENT_MULTIMEDIA,
} from './types';

export const listTalents = createRoutine(TALENT_LIST);
export const retrieveTalent = createRoutine(TALENT_RETRIEVE);
export const retrieveTalentMultimedia = createRoutine(TALENT_MULTIMEDIA);
export const retrieveTalentDedicationHighlights = createRoutine(
  TALENT_HIGHLIGHTS_DEDICATIONS,
);

export const listTalentsSearchParameters = createAction(
  TALENT_LIST_SEARCH_PARAMS,
);
