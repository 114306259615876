import { BookingServiceItem } from '@utils/sdk/types/api-types';
import produce, { Draft } from 'immer';
import {
  retrieveBooking,
  bookingRequest,
  retrieveBookingServices,
  retrieveBookingStatus,
} from './actions';

interface BookingInnerState<T extends {}> {
  items: T[];
  loading: boolean;
  error: any;
}

export type BookingState = {
  loading: boolean;
  error: any;
  services: BookingInnerState<BookingServiceItem>;
  item: any;
  status: BookingInnerState<any>;
};

const initialState: BookingState = {
  loading: false,
  error: null,
  services: {
    loading: false,
    items: [],
    error: null,
  },
  item: null,
  status: {
    loading: false,
    items: [],
    error: null,
  },
};

const reducer = produce((draft: Draft<BookingState>, { type, payload }) => {
  switch (type) {
    case bookingRequest.TRIGGER:
    case retrieveBooking.TRIGGER:
      draft.loading = true;
      draft.error = null;
      break;
    case bookingRequest.FAILURE:
    case retrieveBooking.FAILURE:
      draft.error = payload;
      break;
    case bookingRequest.FULFILL:
    case retrieveBooking.FULFILL:
      draft.loading = false;
      break;
    // SERVICES
    case retrieveBookingServices.TRIGGER:
      draft.services.loading = true;
      draft.services.error = null;
      break;
    case retrieveBookingServices.SUCCESS:
      draft.services.items = payload;
      break;
    case retrieveBookingServices.FAILURE:
      draft.services.error = payload;
      break;
    case retrieveBookingServices.FULFILL:
      draft.services.loading = false;
      break;
    // ITEM
    case retrieveBooking.SUCCESS:
      draft.item = payload;
      break;
    // STATUS
    case retrieveBookingStatus.TRIGGER:
      draft.status.loading = true;
      draft.status.error = null;
      break;
    case retrieveBookingStatus.SUCCESS:
      draft.status.items = payload;
      break;
    case retrieveBookingStatus.FAILURE:
      draft.status.error = payload;
      break;
    // no default
  }
}, initialState);

export default reducer;
