import { FC, useEffect, useState } from 'react';
import { Col, DropdownItem, DropdownMenu, Row } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import CategoryCollage from '@views/ui/category-collage';
import { useSelector } from 'react-redux';
import { getCategoriesHighlightsItems } from '@modules/general/selectors';
import { TalentItem } from '@utils/sdk/types/api-types';

const CategoriesMenu: FC = () => {
  const categories = useSelector(getCategoriesHighlightsItems);
  const [talents, setTalents] = useState<TalentItem[] | undefined>([]);

  const hoverCategory = (newTalents?: TalentItem[]) => () => {
    setTalents(newTalents || []);
  };

  useEffect(() => {
    setTalents(categories?.[0]?.talents || []);
  }, [categories]);

  return (
    <DropdownMenu className="ui__navbar--dropdown ui__navbar--categories py-4 px-1">
      <Row className="mx-0">
        <Col xs={6}>
          {categories.map((category) => (
            <DropdownItem
              key={category.id}
              className="hover-gray"
              to={`/talents?category=${category.id}`}
              tag={NavLink}
              toggle
              onMouseEnter={hoverCategory(category.talents)}
            >
              {category.name}
            </DropdownItem>
          ))}
        </Col>
        <Col xs={6}>
          <Row className="popular-categories__card mx-0">
            <CategoryCollage talents={talents} />
          </Row>
        </Col>
      </Row>
    </DropdownMenu>
  );
};

export default CategoriesMenu;
