import { FC, useState } from 'react';
import {
  Container,
  DropdownToggle,
  Nav,
  NavItem,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  UncontrolledDropdown,
  Dropdown,
} from 'reactstrap';
import { Link, NavLink } from 'react-router-dom';
import { useMediaQueries } from '@react-hook/media-query';
import { useDispatch, useSelector } from 'react-redux';
import IconArrowUp from '@assets/images/iconArrowUp.svg';
import IconArrowDown from '@assets/images/iconArrowDown.svg';
import CarritoActive from '@assets/images/carrito-active.svg';

import { getCartCount } from '@modules/cart/selector';
import { getUserItem } from '@modules/user/selectors';
import { getAuthLoggedIn } from '@modules/auth/selectors';
import LogoNav from '@assets/images/logo-nav.svg';
import { toggleCart } from '@modules/cart/actions';
import NavSidebar from './sidebar';
import SearchTopbar from '../search-topbar';
import ProfileMenu from './profile';
import CategoriesMenu from './categories';

const MainNavbar: FC = () => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(getAuthLoggedIn);
  const user = useSelector(getUserItem);
  const [isOpenSidebar, setIsOpenSidebar] = useState(false);
  const [isOpenCategory, setIsOpenCategory] = useState(false);

  const {
    matches: { isMobile = false },
  } = useMediaQueries({
    isMobile: 'only screen and (max-width: 580px)',
  });

  const toggle = () => {
    if (isMobile) {
      setIsOpenSidebar(!isOpenSidebar);
    }
  };
  const toggleCartSlideout = () => {
    if (!isMobile) {
      dispatch(toggleCart());
    }
  };
  const toggleCategory = () => {
    setIsOpenCategory(!isOpenCategory);
  };
  const itemsInCart = useSelector(getCartCount);

  return (
    <header className="ui__navbar">
      <Container fluid className="px-0">
        <Navbar
          dark
          sticky="top"
          expand="lg"
          container={false}
          className="px-3 px-md-4"
        >
          <NavbarBrand to="/" tag={Link} title="Home">
            <figure className="m-0 p-0">
              <img src={LogoNav} alt="logo-nav" />
            </figure>
          </NavbarBrand>
          {/* MOBILE */}
          <NavSidebar isOpen={isOpenSidebar} toggle={toggle} />
          <Nav navbar className="align-items-center d-md-none flex-row ms-auto">
            {!isLoggedIn && (
              <NavItem>
                <Link
                  to="/auth"
                  className="ui__button ui__button--navbar py-2 text-decoration-none"
                >
                  Ingresar
                </Link>
              </NavItem>
            )}
            <NavItem className="flex-grow-1">
              <SearchTopbar />
            </NavItem>
            <NavItem className="flex-grow-1">
              <NavLink to="/cart">
                {itemsInCart <= 0 ? (
                  <i className="icon-ic_plane-copy" />
                ) : (
                  <img src={CarritoActive} alt="" className="icon-ic_car" />
                )}
              </NavLink>
            </NavItem>
          </Nav>
          <NavbarToggler onClick={toggle} className="ps-3 pe-0" />
          {/* DESK */}
          <Nav navbar className="w-100 align-items-center d-none d-md-flex">
            <NavItem className="flex-grow-1 ">
              <SearchTopbar />
            </NavItem>
            <Dropdown
              nav
              inNavbar
              isOpen={isOpenCategory}
              toggle={toggleCategory}
            >
              <DropdownToggle nav className="p-0">
                {isOpenCategory ? (
                  <img src={IconArrowUp} alt="" className="icon-ic_up" />
                ) : (
                  <img src={IconArrowDown} alt="" className="icon-ic_down" />
                )}
                Categorías
              </DropdownToggle>
              <CategoriesMenu />
            </Dropdown>
            <NavItem>
              <NavLink to="/empresas">
                <i className="icon-ic_suitcase" />
                Para tu empresa
              </NavLink>
            </NavItem>
            {isLoggedIn && (
              <NavItem>
                <NavLink to="/my-requests">
                  <i className="icon-ic_famousstar" />
                  Mis solicitudes
                </NavLink>
              </NavItem>
            )}
            <NavItem className="cursor-pointer" onClick={toggleCartSlideout}>
              {itemsInCart <= 0 ? (
                <i className="icon-ic_plane-copy" />
              ) : (
                <img src={CarritoActive} alt="" className="icon-ic_car" />
              )}
              Carrito
            </NavItem>
            <NavItem>
              {itemsInCart > 0 && (
                <span className="span-cart">{itemsInCart}</span>
              )}
            </NavItem>
            {!isLoggedIn && (
              <NavItem>
                <Link
                  to="/auth"
                  className="ui__button ui__button--navbar py-2 px-4 text-decoration-none"
                >
                  Ingresar
                </Link>
              </NavItem>
            )}

            {isLoggedIn && (
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret className="p-0">
                  <i className="icon-ic_avatar" />
                  {user?.firstName || user?.username}
                </DropdownToggle>
                <ProfileMenu />
              </UncontrolledDropdown>
            )}
          </Nav>
        </Navbar>
      </Container>
    </header>
  );
};

export default MainNavbar;
