import {
  StrictEffect,
  call,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';
import SDK from '@utils/sdk';

import { couponVerify } from './actions';
import { getCartItems, getCouponInputValue } from './selector';

function* couponVerifySaga(): Generator<StrictEffect> {
  try {
    yield put(couponVerify.request());
    const coupon = yield select(getCouponInputValue);
    const items: any = yield select(getCartItems);

    const talents: string[] = [];
    for (const item of items) {
      talents.push(item.talent.id);
    }

    const requestBody = {
      name: coupon,
      talents,
    };

    const api: any = yield call([SDK, 'getApi']);
    const { body }: any = yield call(
      [api.coupon, 'couponVerify'],
      {},
      { requestBody },
    );
    yield put(couponVerify.success(body));
  } catch (error: any) {
    yield put(couponVerify.failure(error?.response?.body?.message || ''));
  } finally {
    yield put(couponVerify.fulfill());
  }
}

export default function* CartWatch() {
  yield takeLatest(couponVerify.TRIGGER, couponVerifySaga);
}
