import produce, { Draft } from 'immer';

import { CountryItem } from '@utils/sdk/types/api-types';
import { locationCountries, locationCities, locationStates } from './actions';

type LocationInnerState = {
  items: (CountryItem | any)[];
  loading: boolean;
  error: any;
};

export type LocationState = {
  countries: LocationInnerState;
  states: LocationInnerState;
  cities: LocationInnerState;
};

const initialState: LocationState = {
  countries: {
    loading: false,
    items: [],
    error: null,
  },
  states: {
    loading: false,
    items: [],
    error: null,
  },
  cities: {
    loading: false,
    items: [],
    error: null,
  },
};

const reducer = produce((draft: Draft<LocationState>, { type, payload }) => {
  switch (type) {
    // COUNTRIES
    case locationCountries.TRIGGER:
      draft.countries.loading = true;
      draft.countries.error = null;
      break;
    case locationCountries.SUCCESS:
      draft.countries.items = payload;
      break;
    case locationCountries.FAILURE:
      draft.countries.error = payload;
      break;
    case locationCountries.FULFILL:
      draft.countries.loading = false;
      break;
    // STATES
    case locationStates.TRIGGER:
      draft.states.loading = true;
      draft.states.error = null;
      break;
    case locationStates.SUCCESS:
      draft.states.items = payload;
      break;
    case locationStates.FAILURE:
      draft.states.error = payload;
      break;
    case locationStates.FULFILL:
      draft.states.loading = false;
      break;
    // CITIES
    case locationCities.TRIGGER:
      draft.cities.loading = true;
      draft.cities.error = null;
      break;
    case locationCities.SUCCESS:
      draft.cities.items = payload;
      break;
    case locationCities.FAILURE:
      draft.cities.error = payload;
      break;
    case locationCities.FULFILL:
      draft.cities.loading = false;
      break;
  }
}, initialState);

export default reducer;
