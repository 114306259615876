import produce, { Draft } from 'immer';
import { RequestItem, UserItem } from '@utils/sdk/types/api-types';

import {
  retrieveUser,
  updateUser,
  forgotPassword,
  resetPassword,
  changePassword,
  requestsUser,
  deleteUserAccount,
} from './actions';

export type UserState = {
  loading: boolean;
  error: string | null;
  item: UserItem | null;
  requests: RequestItem[];
  refreshingRequest: boolean;
  forgotPassword: {
    token: string | null;
    email: string | null;
  };
};

const initialState: UserState = {
  loading: false,
  error: null,
  item: null,
  requests: [],
  refreshingRequest: false,
  forgotPassword: {
    token: null,
    email: null,
  },
};

const reducer = produce((draft: Draft<UserState>, { type, payload }) => {
  switch (type) {
    case retrieveUser.TRIGGER:
    case updateUser.TRIGGER:
    case changePassword.TRIGGER:
    case resetPassword.TRIGGER:
    case forgotPassword.TRIGGER:
    case requestsUser.TRIGGER:
    case deleteUserAccount.TRIGGER:
      draft.loading = true;
      draft.error = null;
      break;
    case updateUser.SUCCESS:
    case retrieveUser.SUCCESS:
      draft.loading = false;
      draft.item = payload;
      break;
    case requestsUser.SUCCESS:
      draft.requests = payload;
      break;
    case changePassword.FAILURE:
    case resetPassword.FAILURE:
    case forgotPassword.FAILURE:
    case updateUser.FAILURE:
    case requestsUser.FAILURE:
    case retrieveUser.FAILURE:
    case deleteUserAccount.FAILURE:
      draft.loading = false;
      draft.error = payload;
      break;
    case changePassword.FULFILL:
    case resetPassword.FULFILL:
    case forgotPassword.FULFILL:
    case updateUser.FULFILL:
    case requestsUser.FULFILL:
    case retrieveUser.FULFILL:
    case deleteUserAccount.FULFILL:
      draft.loading = false;
      break;
  }
}, initialState);

export default reducer;
