import { RootState } from '@store/types';
import { createSelector } from 'reselect';

export const getCart = (state: RootState) => state.cart;

export const getCartIsOpen = createSelector(getCart, (cart) => cart.isOpen);
export const getCartItems = createSelector(getCart, (cart) => cart.items);
export const getCartCount = createSelector(
  getCartItems,
  (items) => items.length,
);
export const getCartTotal = createSelector(getCartItems, (items) =>
  items.reduce((sum, item) => sum + item.service.purchaseService.value, 0),
);
export const getCoupon = createSelector(getCart, (cart) => cart.coupon);
export const getCouponInputValue = createSelector(
  getCoupon,
  (coupon) => coupon.inputValue,
);
export const getCouponItem = createSelector(getCoupon, (coupon) => coupon.item);
export const getCouponError = createSelector(
  getCoupon,
  (coupon) => coupon.error,
);
export const getCouponSubmited = createSelector(
  getCoupon,
  (coupon) => coupon.submited,
);

export const getTotalCart = createSelector(getCartItems, (items) =>
  items.reduce(
    (sum, item) => sum + (item?.service?.purchaseService?.value || 0),
    0,
  ),
);

export const getDiscount = createSelector(
  [getTotalCart, getCouponItem, getCartItems],
  (total, coupon, items) => {
    if (!coupon) return 0;
    if (coupon.isGeneral) {
      return total * (coupon.value / 100);
    }
    if (coupon.talents.length > 0) {
      return items.reduce((sum, item) => {
        const exist = coupon.talents.find(
          (talent) => talent.id === item?.talent?.id,
        );
        if (exist) {
          return (
            sum +
            (item?.service?.purchaseService?.value || 0) * (coupon.value / 100)
          );
        }
        return sum;
      }, 0);
    }
    return 0;
  },
);
