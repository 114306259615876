import { FC } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ConnectedRouter } from 'connected-react-router';
import { HelmetProvider } from 'react-helmet-async';
import * as Sentry from '@sentry/react';
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';
import Validators from 'redux-form-validators';

import SdkLoader from '@views/components/sdk-loader';
import Routes from 'routes';
import routesConfig from '@routes/config';
import { store, persistor } from 'store';
import { history } from '@store/history';
import ValidatorMessages from '@utils/forms/validators-messages';
import ScrollToTop from '@views/components/scroll-to-top';

ReactGA.initialize(process.env.REACT_APP_GA_TRACK_ID || '', {
  debug: process.env.NODE_ENV !== 'production',
});
TagManager.initialize({
  gtmId: process.env.REACT_APP_GTM_TRACK_ID || '',
});

Object.assign(Validators.messages, ValidatorMessages);

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new Sentry.BrowserTracing()],
  tracesSampleRate: 1,
  normalizeDepth: 10,
  enabled: process.env.NODE_ENV === 'production',
});

const App: FC = () => (
  <HelmetProvider>
    <Provider store={store}>
      <PersistGate loading={false} persistor={persistor}>
        <SdkLoader>
          <ConnectedRouter history={history}>
            <ScrollToTop />
            <Routes store={store} routes={routesConfig} />
          </ConnectedRouter>
        </SdkLoader>
      </PersistGate>
    </Provider>
  </HelmetProvider>
);

export default App;
