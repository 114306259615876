import { FC } from 'react';
import { Container, Row, Col } from 'reactstrap';

import LogoWhite from '@assets/images/logo-full-white.svg';
import InstragramLogo from '@assets/images/instagram-btn.svg';
import FacebookLogo from '@assets/images/facebook-btn.svg';
import TiktokLogo from '@assets/images/tiktok-btn.svg';
import Foundation1 from '@assets/images/adopta-un-angel.svg';
import Foundation2 from '@assets/images/juanfe.svg';
import BffTape from '@views/ui/bff-tape';

type FooterProperties = {
  columnSupportUs?: number;
  isTape?: boolean;
};

const Footer: FC<FooterProperties> = ({
  columnSupportUs = 8,
  isTape = true,
}) => (
  <>
    {isTape && <BffTape />}
    <footer className="footer">
      <Container fluid className="px-4">
        <Row className="justify-content-md-center">
          <Col className="footer__logo-row" xl={1} xs={4}>
            <figure className="footer__logo-container">
              <img alt="logo-wite" src={LogoWhite} />
            </figure>
          </Col>
          <Col className="footer__icons-row" xl={2} xs={6}>
            <Row className="footer__icons-title">
              <span>Síguenos</span>
            </Row>
            <Row className="footer__icons-container">
              <a
                href="https://www.instagram.com/bff_app/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={InstragramLogo} alt="" className="footer__icons" />
              </a>
              <a
                href="https://www.facebook.com/Best-Friend-Famoso-101209485326009"
                target="_blank"
                rel="noreferrer"
              >
                <img src={FacebookLogo} alt="" className="footer__icons" />
              </a>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a href="" target="_blank">
                <img src={TiktokLogo} alt="" className="footer__icons" />
              </a>
            </Row>
          </Col>
          <Col xl={2} xs={12}>
            <span className="footer__link-title">Apoyamos a</span>
            <Row className="align-items-center">
              <Col xs={6} md={2} xl={columnSupportUs}>
                <img
                  src={Foundation1}
                  alt="apoyamos-un-angel"
                  className="w-100 my-3"
                />
              </Col>
              <Col xs={6} md={2} xl={columnSupportUs}>
                <img
                  src={Foundation2}
                  alt="apoyamos-un-angel"
                  className="w-100"
                />
              </Col>
            </Row>
          </Col>
          <Col xl={2} xs={12}>
            <span className="footer__link-title">Talento</span>
            <a
              href="https://web.bestfriendfamoso.com/aplicar-como-famoso"
              className="footer__links"
            >
              Quiero aplicar como talento
            </a>
            <a
              href="https://web.bestfriendfamoso.com/calculadora/"
              className="footer__links"
            >
              ¿Cuánto puedo cobrar?
            </a>
          </Col>
          <Col xl={2} xs={12}>
            <span className="footer__link-title">Transparencia</span>
            <a
              href="https://web.bestfriendfamoso.com/politica-privacidad/"
              className="footer__links"
            >
              Políticas de privacidad
            </a>
            <a
              href="https://web.bestfriendfamoso.com/terminos-condiciones/"
              className="footer__links"
            >
              Términos y condiciones
            </a>
          </Col>
          <Col xl={2} xs={12}>
            <span className="footer__link-title">Ayuda</span>
            <a
              href="https://web.bestfriendfamoso.com/client-support/?source=client"
              target="_blank"
              className="footer__links"
              rel="noreferrer"
            >
              Chat en línea
            </a>
            <a
              href="mailto:soporte@bestfriendfamoso.com"
              target="_blank"
              className="footer__links"
              rel="noreferrer"
            >
              Escríbenos
            </a>
            <a
              href="https://intercom.help/BestFriendFamoso/es/articles/6222073-preguntas-frequentes-fans"
              className="footer__links"
            >
              Preguntas frecuentes
            </a>
          </Col>
        </Row>
        <Row className="footer__copy-container flex-lg-row-reverse align-items-lg-end mt-4 justify-content-center">
          <Col xl={6} xs={12} className="footer__copy-text text-center">
            &copy; Copyright {new Date().getFullYear()} Red Valley. All rights
            reserved.
          </Col>
        </Row>
      </Container>
    </footer>
  </>
);
export default Footer;
