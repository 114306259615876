/* eslint-disable @typescript-eslint/no-explicit-any */
import { PersistConfig } from 'redux-persist';
import localForage from 'localforage';

const isDebug = process.env.NODE_ENV !== 'production';

localForage.config({
  name: 'BFF WEB',
  storeName: 'bff-web',
  version: 1,
});

export const rootPersistConfig: PersistConfig<any> = {
  key: 'root',
  storage: localForage,
  debug: isDebug,
  whitelist: ['auth', 'user', 'cart', 'general'],
};

export default rootPersistConfig;
