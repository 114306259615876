import produce, { Draft } from 'immer';
import {
  CouponItem,
  InvoiceItem,
  TalentItem,
  TalentServiceItem,
} from '@utils/sdk/types/api-types';
import {
  addToCart,
  couponClear,
  couponInputValueChange,
  couponVerify,
  emptyCart,
  removeFromCart,
  toggleCart,
} from './actions';

export type CartItemType = {
  talent: TalentItem;
  service: TalentServiceItem;
  invoice: InvoiceItem;
};

export type CouponType = {
  inputValue: string;
  item: CouponItem | null;
  error: string | null;
  submited: boolean;
};

export type CartState = {
  isOpen: boolean;
  items: CartItemType[];
  coupon: CouponType;
};

const initialState: CartState = {
  isOpen: false,
  items: [],
  coupon: {
    inputValue: '',
    item: null,
    error: null,
    submited: false,
  },
};

const reducer = produce((draft: Draft<CartState>, { type, payload }) => {
  switch (type) {
    case toggleCart.toString():
      draft.isOpen = !draft.isOpen;
      break;
    case addToCart.toString():
      draft.items.push(payload);
      break;
    case removeFromCart.toString():
      draft.items.splice(payload.index, 1);
      break;
    case emptyCart.toString():
      draft.coupon.inputValue = '';
      draft.coupon.item = null;
      draft.coupon.error = null;
      draft.coupon.submited = false;
      draft.items = [];
      break;
    case couponInputValueChange.toString():
      draft.coupon.inputValue = payload;
      break;
    case couponVerify.REQUEST:
      draft.coupon.error = null;
      draft.coupon.submited = true;
      break;
    case couponVerify.SUCCESS:
      draft.coupon.item = payload;
      break;
    case couponVerify.FAILURE:
      draft.coupon.item = null;
      draft.coupon.error = payload;
      break;
    case couponVerify.FULFILL:
      draft.coupon.submited = false;
      break;
    case couponClear.toString():
      draft.coupon.inputValue = '';
      draft.coupon.item = null;
      draft.coupon.error = null;
      draft.coupon.submited = false;
      break;
    // no default
  }
}, initialState);

export default reducer;
