/* eslint-disable import/no-anonymous-default-export */
export default {
  absence: {
    id: 'form.errors.absence',
    defaultMessage: 'Debe estar en blanco',
  },
  acceptance: {
    id: 'form.errors.acceptance',
    defaultMessage: 'Debe ser aceptado',
  },
  confirmation: {
    id: 'form.errors.confirmation',
    defaultMessage: 'No coincide con `{fieldLabel}`',
  },
  dateFormat: {
    id: 'form.errors.dateFormat',
    defaultMessage: 'Formato esperado: {format}',
  },
  dateInvalid: {
    id: 'form.errors.dateInvalid',
    defaultMessage: 'No es una fecha válida',
  },
  dateRange: {
    id: 'form.errors.dateRange',
    defaultMessage: 'Debería ser {op} {date}',
  },
  email: {
    id: 'form.errors.email',
    defaultMessage: 'Formato de correo inválido',
  },
  emailDomain: {
    id: 'form.errors.emailDomain',
    defaultMessage: '{domain} no es un dominio aceptado',
  },
  equalTo: {
    id: 'form.errors.equalTo',
    defaultMessage: 'Debe ser igual a {count, number}',
  },
  even: {
    id: 'form.errors.even',
    defaultMessage: 'Debe ser parejo',
  },
  exclusion: {
    id: 'form.errors.exclusion',
    defaultMessage: 'Requerido',
  },
  file: {
    id: 'form.errors.file',
    defaultMessage: 'No es un archivo',
  },
  fileAccept: {
    id: 'form.errors.fileAccept',
    defaultMessage:
      'Archivo inválido {count, plural, one {type} other {types ({count})}}',
  },
  fileTooBig: {
    id: 'form.errors.fileTooBig',
    defaultMessage:
      '{count, plural, one {is} other {{count} files are}} demasiado grande (el máximo es {count, plural, one {{size}} other {{size} each}})',
  },
  fileTooFew: {
    id: 'form.errors.fileTooFew',
    defaultMessage: 'Número inválido de archivos (el mínimo es {count})',
  },
  fileTooMany: {
    id: 'form.errors.fileTooMany',
    defaultMessage: 'Número de archivos no válido (el máximo es {count})',
  },
  fileTooSmall: {
    id: 'form.errors.fileTooSmall',
    defaultMessage:
      '{count, plural, one {is} other {{count} files are}} demasiado pequeña (mínimo es {count, plural, one {{size}} other {{size} each}})',
  },
  greaterThan: {
    id: 'form.errors.greaterThan',
    defaultMessage: 'Debe ser mayor que {count, number}',
  },
  greaterThanOrEqualTo: {
    id: 'form.errors.greaterThanOrEqualTo',
    defaultMessage: 'Debe ser mayor o igual que {count, number}',
  },
  inclusion: {
    id: 'form.errors.inclusion',
    defaultMessage: 'No está incluido en la lista',
  },
  invalid: {
    id: 'form.errors.invalid',
    defaultMessage: 'Es inválido',
  },
  lessThan: {
    id: 'form.errors.lessThan',
    defaultMessage: 'Debe ser menor que {count, number}',
  },
  lessThanOrEqualTo: {
    id: 'form.errors.lessThanOrEqualTo',
    defaultMessage: 'Debe ser menor o igual a {count, number}',
  },
  notAnInteger: {
    id: 'form.errors.notAnInteger',
    defaultMessage: 'No es un entero',
  },
  notANumber: {
    id: 'form.errors.notANumber',
    defaultMessage: 'No es un número',
  },
  odd: {
    id: 'form.errors.odd',
    defaultMessage: 'must be odd',
  },
  otherThan: {
    id: 'form.errors.otherThan',
    defaultMessage: 'Debe ser distinta de {count, number}',
  },
  presence: {
    id: 'form.errors.presence',
    defaultMessage: 'Requerido',
  },
  tooLong: {
    id: 'form.errors.tooLong',
    defaultMessage:
      'Máximo {count, number} {count, plural, one {carácter} other {caracteres}}',
  },
  tooShort: {
    id: 'form.errors.tooShort',
    defaultMessage:
      'Mínimo {count, number} {count, plural, one {carácter} other {caracteres}}',
  },
  url: {
    id: 'form.errors.url',
    defaultMessage: 'No es una url válida',
  },
  wrongLength: {
    id: 'form.errors.wrongLength',
    defaultMessage:
      'Es la longitud incorrecta (debiera ser {count, number} {count, plural, one {carácter} other {caracteres}})',
  },
  requiredIf: {
    id: 'form.errors.requiredIf',
    defaultMessage: 'Requerido',
  },
  socialNetwork: {
    id: 'form.errors.socialNetwork',
    defaultMessage: 'Link de cuenta inválido',
  },
  emoji: {
    id: 'form.errors.emoji',
    defaultMessage: 'No se permite carga de iconos',
  },
  phone: {
    id: 'form.errors.phone',
    defaultMessage: 'Teléfono inválido',
  },
};
