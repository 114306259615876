import { RootState } from '@store/types';
import { createSelector } from 'reselect';

export const getGeneral = (state: RootState) => state.general;

// Services
export const getGeneralServices = createSelector(
  getGeneral,
  (general) => general.services,
);

export const getServicesLoading = createSelector(
  getGeneralServices,
  (services) => services.loading,
);
export const getServicesItems = createSelector(
  getGeneralServices,
  (services) => services.items,
);
// Categories
export const getGeneralCategories = createSelector(
  getGeneral,
  (general) => general.categories,
);

export const getCategoriesLoading = createSelector(
  getGeneralCategories,
  (categories) => categories.loading,
);
export const getCategoriesItems = createSelector(
  getGeneralCategories,
  (categories) => categories.items,
);

// CATEGORY HIGHILGHTS
export const getGeneralCategoryHighlights = createSelector(
  getGeneral,
  (general) => general.categoryHighlights,
);

export const getCategoriesHighlightsLoading = createSelector(
  getGeneralCategoryHighlights,
  (categories) => categories.loading,
);
export const getCategoriesHighlightsItems = createSelector(
  getGeneralCategoryHighlights,
  (categories) => categories.items,
);

// Categories
export const getGeneralOccasions = createSelector(
  getGeneral,
  (general) => general.occasions,
);

export const getOccasionsLoading = createSelector(
  getGeneralOccasions,
  (occasions) => occasions.loading,
);
export const getOccasionsItems = createSelector(
  getGeneralOccasions,
  (occasions) => occasions.items,
);

// identificationTypes
export const getGeneralDocumentTypes = createSelector(
  getGeneral,
  (general) => general.identificationTypes,
);

export const getDocumentTypesLoading = createSelector(
  getGeneralDocumentTypes,
  (identificationTypes) => identificationTypes.loading,
);
export const getDocumentTypesItems = createSelector(
  getGeneralDocumentTypes,
  (identificationTypes) => identificationTypes.items,
);
export const getDocumentTypesOptions = createSelector(
  getDocumentTypesItems,
  (identificationTypes) =>
    identificationTypes.map((item) => ({
      label: item.name,
      value: item.id,
    })),
);

export const getDocumentTypesOptionsBooking = createSelector(
  getDocumentTypesItems,
  (identificationTypes) =>
    identificationTypes.map((item) => ({
      label: item.name,
      value: item.name,
    })),
);
