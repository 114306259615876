/* eslint-disable import/prefer-default-export */
import { createAction } from 'redux-actions';
import { createRoutine } from 'redux-saga-routines';
import {
  TOGGLE_CART,
  ADD_TO_CART,
  EMPTY_CART,
  REMOVE_FROM_CART,
  COUPON_VERIFY,
  COUPON_INPUT_VALUE_CHANGE,
  COUPON_CLEAR,
} from './types';

export const toggleCart = createAction(TOGGLE_CART);
export const addToCart = createAction(ADD_TO_CART);
export const removeFromCart = createAction(REMOVE_FROM_CART);
export const emptyCart = createAction(EMPTY_CART);
export const couponInputValueChange = createAction(COUPON_INPUT_VALUE_CHANGE);
export const couponVerify = createRoutine(COUPON_VERIFY);
export const couponClear = createAction(COUPON_CLEAR);
