import { TalentItem } from '@utils/sdk/types/api-types';
import { push } from 'connected-react-router';
import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { Col, Row } from 'reactstrap';
import {
  LazyLoadImage,
  trackWindowScroll,
  ScrollPosition,
} from 'react-lazy-load-image-component';

type TalentSearchCardProperties = {
  item: TalentItem;
  toggle: () => void;
  scrollPosition: ScrollPosition;
};
const TalentSearchCard: FC<TalentSearchCardProperties> = ({
  item,
  toggle,
  scrollPosition,
}) => {
  const dispatch = useDispatch();
  const handleTalent = () => {
    toggle();
    dispatch(push(`/talents/${item.id}`));
  };

  return (
    <Row
      onClick={handleTalent}
      className="search-topbar__card mb-3 mx-0 align-items-center cursor-pointer"
    >
      <LazyLoadImage
        alt="talent-profile"
        src={item?.profileImage?.url}
        className="search-topbar__card--image px-0 me-2"
        scrollPosition={scrollPosition}
      />
      <Col className="px-0" xs={6}>
        <h4 className="search-topbar__card--talent-name mb-0">
          {item?.firstName} {item?.lastName}
        </h4>
        <p className="search-topbar__card--talent-profession mb-0">
          {item.profession}
        </p>
      </Col>
    </Row>
  );
};

export default trackWindowScroll(TalentSearchCard);
