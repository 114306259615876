import produce, { Draft } from 'immer';

import { DocumentTypeItem } from '@utils/sdk/types/api-types';
import { peopleDocumentsTypes } from './actions';

export type PeopleState = {
  loading: boolean;
  error: any;
  documentsTypes: DocumentTypeItem[];
};

const initialState: PeopleState = {
  loading: false,
  error: null,
  documentsTypes: [],
};

const reducer = produce((draft: Draft<PeopleState>, { type, payload }) => {
  switch (type) {
    case peopleDocumentsTypes.TRIGGER:
      draft.loading = true;
      draft.error = null;
      break;
    case peopleDocumentsTypes.SUCCESS:
      draft.documentsTypes = payload;
      break;
    case peopleDocumentsTypes.FAILURE:
      draft.error = payload;
      break;
    case peopleDocumentsTypes.FULFILL:
      draft.loading = false;
      break;
  }
}, initialState);

export default reducer;
