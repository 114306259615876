import { Offcanvas, OffcanvasBody, OffcanvasHeader } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getCartIsOpen } from '@modules/cart/selector';
import { toggleCart } from '@modules/cart/actions';
import Cart from '../cart';

const CartSlideOut = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector(getCartIsOpen);
  const toggle = () => {
    dispatch(toggleCart());
  };

  return (
    <Offcanvas
      className="cart__slideout"
      isOpen={isOpen}
      direction="end"
      scrollable
    >
      <OffcanvasHeader toggle={toggle}>
        <h1 className="cart__slideout--title">CARRITO DE COMPRAS</h1>
      </OffcanvasHeader>
      <OffcanvasBody>
        <Cart />
      </OffcanvasBody>
    </Offcanvas>
  );
};

export default CartSlideOut;
