import { FC } from 'react';
import { TalentServiceItem } from '@utils/sdk/types/api-types';
import { Col, Row } from 'reactstrap';
import {
  LazyLoadImage,
  ScrollPosition,
  trackWindowScroll,
} from 'react-lazy-load-image-component';

type ServicePriceCardProperties = {
  scrollPosition: ScrollPosition;
};

const ServicePriceCard: FC<ServicePriceCardProperties & TalentServiceItem> = ({
  purchaseService: { value },
  service: { color, image, name, description },
  scrollPosition,
}) => (
  <div className="services__price-card my-2">
    <Row className="w-100 mx-0">
      <Col className="flex-grow-0">
        <div
          className="services__price-card--icon text-center"
          style={{ backgroundColor: color }}
        >
          <LazyLoadImage
            src={image}
            alt={name}
            className=""
            scrollPosition={scrollPosition}
          />
        </div>
      </Col>
      <Col className="flex-grow-1 d-flex flex-column justify-content-center">
        <p className="services__price-card--title">{name}</p>
        <p className="services__price-card--subtitle my-1">{description}</p>
        <p className="services__price-card--price">${value} USD</p>
      </Col>
    </Row>
  </div>
);

export default trackWindowScroll(ServicePriceCard);
