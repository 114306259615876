import { RootState } from '@store/types';
import { createSelector } from 'reselect';

export const getTalent = (state: RootState) => state.talent;

export const getTalentError = createSelector(
  getTalent,
  (talent) => talent.error,
);

// PROFILE SELECTORS
export const getTalentProfile = createSelector(
  getTalent,
  (talent) => talent.profile,
);
export const getProfileLoading = createSelector(
  getTalentProfile,
  (profile) => profile.loading,
);
export const getTalentProfileItem = createSelector(
  getTalentProfile,
  (profile) => profile.item,
);
export const getCategoriesTalentProfile = createSelector(
  getTalentProfileItem,
  (item) => item?.categories,
);

export const getServicesTalentProfile = createSelector(
  getTalentProfileItem,
  (item) => {
    const services = item?.services;
    return services && services.length > 0
      ? [...services].sort((a, b) => (a.service.name > b.service.name ? 1 : -1))
      : [];
  },
);

export const getTalentMultimedia = createSelector(
  getTalentProfile,
  (profile) => profile.multimedia,
);

// LIST SELECTORS
export const getTalentList = createSelector(getTalent, (talent) => talent.list);
export const getTalentListLoading = createSelector(
  getTalentList,
  (list) => list.loading,
);
export const getTalentListItems = createSelector(
  getTalentList,
  (list) => list.items,
);
export const getTalentListMeta = createSelector(
  getTalentList,
  (list) => list.meta,
);

// DEDICATION HIGHLIGHT SELECTORS
export const getTalentDedications = createSelector(
  getTalent,
  (talent) => talent.dedications,
);
export const getTalentDedicationsLoading = createSelector(
  getTalentDedications,
  (dedications) => dedications.loading,
);
export const getTalentDedicationsItems = createSelector(
  getTalentDedications,
  (dedications) => dedications.items,
);
