import { createSelector } from 'reselect';
import { RootState } from '@store/types';
import { AuthState } from './reducer';

export const getAuth = (state: RootState): AuthState => state.auth;
export const getAuthToken = createSelector(getAuth, (auth) => auth.token);
export const getAuthLoading = createSelector(getAuth, (auth) => auth.loading);
export const getAuthLoggedIn = createSelector(
  getAuth,
  (auth) => auth.isLoggedIn,
);

export const getRetryActions = createSelector(getAuth, (auth) =>
  JSON.parse(auth.pendingActions),
);

export const getAuthRefreshToken = createSelector(
  getAuth,
  (auth) => auth.refreshToken,
);
