import { bindRoutineToReduxForm, createRoutine } from 'redux-saga-routines';
import {
  RETRIEVE_BOOKING,
  RETRIEVE_BOOKING_SERVICES,
  BOOKING_REQUEST,
  BOOKING_STATUS,
} from './types';

export const retrieveBooking = createRoutine(RETRIEVE_BOOKING);
export const retrieveBookingServices = createRoutine(RETRIEVE_BOOKING_SERVICES);
export const bookingRequest = createRoutine(BOOKING_REQUEST);
export const bindedBookingRequest = bindRoutineToReduxForm(bookingRequest);
export const retrieveBookingStatus = createRoutine(BOOKING_STATUS);
