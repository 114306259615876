import { FC } from 'react';
import { Button, Col, Row } from 'reactstrap';
import { TalentItem, TalentServiceItem } from '@utils/sdk/types/api-types';
import { useDispatch } from 'react-redux';
import { removeFromCart } from '@modules/cart/actions';
import {
  LazyLoadImage,
  trackWindowScroll,
  ScrollPosition,
} from 'react-lazy-load-image-component';

export type CartItemProperties = {
  talent: TalentItem;
  index: number;
  service: TalentServiceItem; // Need to check if usable for adding items to the cart
  onClick?: () => void;
  scrollPosition: ScrollPosition;
};

const CartItem: FC<CartItemProperties> = ({
  talent,
  service,
  index,
  onClick,
  scrollPosition,
}) => {
  const dispatch = useDispatch();

  const removeItem = () => {
    dispatch(removeFromCart({ index, talent, service }));
  };

  return (
    <div>
      <Row className="cart__card mb-2 mx-0">
        <Col className="flex-grow-0">
          <LazyLoadImage
            className="cart__card--image"
            src={talent?.profileImage?.url}
            alt={talent.firstName}
            scrollPosition={scrollPosition}
          />
        </Col>
        <Col className="d-flex justify-content-center flex-column flex-grow-1">
          <p className="cart__card--title">
            {talent?.firstName} {talent?.lastName}
          </p>
          <p className="cart__card--subtitle my-1">{service?.service.name}</p>
          <p className="cart__card--price">
            ${service?.purchaseService.value} USD
          </p>
          <Button
            onClick={onClick}
            type="button"
            className="ui__button ui__button--link cart__card--button-details mt-1"
          >
            Detalles
          </Button>
        </Col>
        <Col className="d-flex justify-content-center flex-column flex-grow-0">
          <Button
            onClick={removeItem}
            className="ui__button ui__button--transparent cart__card--button-delete py-2"
          >
            <i className="icon-ic_garbage" />
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default trackWindowScroll(CartItem);
