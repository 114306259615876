const PREFIX = 'AUTH';

export const AUTH_CHECK = `${PREFIX}/CHECK`;
export const AUTH_REGISTER = `${PREFIX}/REGISTER`;
export const AUTH_LOGIN = `${PREFIX}/LOGIN`;
export const AUTH_LOGIN_GOOGLE = `${PREFIX}/LOGIN_GOOGLE`;
export const AUTH_LOGIN_APPLE = `${PREFIX}/LOGIN_APPLE`;
export const AUTH_LOGOUT = `${PREFIX}/LOGOUT`;
export const AUTH_CLEAR_SESSION = `${PREFIX}/CLEAR_SESSION`;
export const AUTH_FORGOT_PASSWORD = `${PREFIX}/FORGOT_PASSWORD`;
export const AUTH_RECOVER_PASSWORD = `${PREFIX}/RECOVER_PASSWORD`;
export const AUTH_CHECK_PASSWORD_CODE = `${PREFIX}/CHECK_PASSWORD_CODE`;
export const AUTH_CHANGE_PASSWORD = `${PREFIX}/CHANGE_PASSWORD`;

export const AUTH_REFRESH_TOKEN = `${PREFIX}/REFRESH_TOKEN`;
export const AUTH_DEBOUNCE_REFRESH = `${PREFIX}/DEBOUNCE_REFRESH`;
export const AUTH_RETRY_ACTIONS = `${PREFIX}/RETRY_ACTIONS`;
