/* eslint-disable @typescript-eslint/ban-ts-comment */
import { all, call, spawn, StrictEffect } from 'redux-saga/effects';
import { routinePromiseWatcherSaga } from 'redux-saga-routines';
import navWatch from '@modules/nav/sagas';
import authWatch from '@modules/auth/sagas';
import userWatch from '@modules/user/sagas';
import talentsWatch from '@modules/talent/sagas';
import peopleWatch from '@modules/people/sagas';
import locationWatch from '@modules/location/sagas';
import generalWatch from '@modules/general/sagas';
import invoicesWatch from '@modules/invoice/sagas';
import checkoutWatch from '@modules/checkout/sagas';
import bookingWatch from '@modules/booking/sagas';
import CartWatch from '@modules/cart/sagas';

function* rootSaga(): Generator<StrictEffect> {
  yield all([
    // external
    call(routinePromiseWatcherSaga),
    spawn(navWatch),
    call(authWatch),
    call(userWatch),
    call(talentsWatch),
    call(peopleWatch),
    call(locationWatch),
    call(generalWatch),
    call(invoicesWatch),
    call(checkoutWatch),
    call(bookingWatch),
    call(CartWatch),
  ]);
}

export default rootSaga;
