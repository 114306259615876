import { FC } from 'react';

type BffTapeProperties = {
  title?: string;
};

const BffTape: FC<BffTapeProperties> = ({ title = 'BEST FRIEND FAMOSO' }) => (
  <section className="bff-line">
    <span className="bff-line__title">
      {title} <i className="icon-ic_famousstar_active bff-line__icon mx-2" />
      {title} <i className="icon-ic_famousstar_active bff-line__icon mx-2" />
      {title} <i className="icon-ic_famousstar_active bff-line__icon mx-2" />
      {title} <i className="icon-ic_famousstar_active bff-line__icon mx-2" />
      {title} <i className="icon-ic_famousstar_active bff-line__icon mx-2" />
      {title} <i className="icon-ic_famousstar_active bff-line__icon mx-2" />
      {title} <i className="icon-ic_famousstar_active bff-line__icon mx-2" />
    </span>
    <span className="bff-line__title title-2">
      {title} <i className="icon-ic_famousstar_active bff-line__icon mx-2" />
      {title} <i className="icon-ic_famousstar_active bff-line__icon mx-2" />
      {title} <i className="icon-ic_famousstar_active bff-line__icon mx-2" />
      {title} <i className="icon-ic_famousstar_active bff-line__icon mx-2" />
      {title} <i className="icon-ic_famousstar_active bff-line__icon mx-2" />
      {title} <i className="icon-ic_famousstar_active bff-line__icon mx-2" />
      {title} <i className="icon-ic_famousstar_active bff-line__icon mx-2" />
    </span>
  </section>
);

export default BffTape;
