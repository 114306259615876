import produce, { Draft } from 'immer';
import { emptyCart } from '@modules/cart/actions';
import { InvoiceItem } from '@utils/sdk/types/api-types';
import {
  createPendingInvoice,
  retrieveInvoice,
  updateInvoice,
} from './actions';

export type InvoiceState = {
  loading: boolean;
  error: any;
  item: InvoiceItem | null;
};

const initialState: InvoiceState = {
  loading: false,
  error: null,
  item: null,
};

const reducer = produce((draft: Draft<InvoiceState>, { type, payload }) => {
  switch (type) {
    case retrieveInvoice.TRIGGER:
    case updateInvoice.TRIGGER:
      draft.loading = true;
      draft.error = null;
      break;
    case createPendingInvoice.SUCCESS:
    case retrieveInvoice.SUCCESS:
    case updateInvoice.SUCCESS:
      draft.item = payload;
      break;
    case retrieveInvoice.FAILURE:
    case updateInvoice.FAILURE:
      draft.error = payload;
      break;
    case retrieveInvoice.FULFILL:
    case updateInvoice.FULFILL:
      draft.loading = false;
      break;
    case emptyCart.toString():
      draft.item = null;
      break;
  }
}, initialState);

export default reducer;
