import { StrictEffect, call, put, takeLatest } from 'redux-saga/effects';
import SDK from '@utils/sdk';
import { peopleDocumentsTypes } from './actions';

export function* peopleDocumentsTypesSaga(): Generator<StrictEffect> {
  try {
    yield put(peopleDocumentsTypes.request());
    const api: any = yield call([SDK, 'getApi']);
    const { body }: any = yield call([api.people, 'peopleListTypeDocument']);
    yield put(peopleDocumentsTypes.success(body));
  } catch (error) {
    yield put(peopleDocumentsTypes.failure(error));
  } finally {
    yield put(peopleDocumentsTypes.fulfill());
  }
}

export default function* peopleWatch() {
  yield takeLatest(peopleDocumentsTypes.TRIGGER, peopleDocumentsTypesSaga);
}
