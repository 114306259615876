import GoogleAnalyticsGtag, {
  trackEvent,
  trackPageView,
} from '@redux-beacon/google-analytics-gtag';
import { createMiddleware } from 'redux-beacon';
import { LOCATION_CHANGE } from 'connected-react-router';
import { addToCart, removeFromCart } from '@modules/cart/actions';
import { listTalents } from '@modules/talent/actions';

export const ga = GoogleAnalyticsGtag(process.env.REACT_APP_GA_TRACK_ID || '');

export const eventsMap = {
  [LOCATION_CHANGE]: trackPageView(({ payload }) => ({
    location: payload.location,
    path: payload.location.pathname,
  })),
  [addToCart.toString()]: trackEvent(({ payload }) => ({
    category: 'add_to_cart',
    action: 'add_to_cart',
    fieldsObject: {
      currencyCode: payload.service.currency,
      value: payload.service.purchaseService.value,
      items: [
        {
          name: payload.service.service.name,
          id: payload.service.purchaseService.id,
          price: payload.service.purchaseService.value,
          brand: payload.talent.shortName,
          category: payload.service.service?.type,
          quantity: 1,
        },
      ],
    },
  })),
  [removeFromCart.toString()]: trackEvent(({ payload }) => ({
    category: 'remove_from_cart',
    action: 'remove_from_cart',
    fieldsObject: {
      currencyCode: payload.service.currency,
      value: payload.service.purchaseService.value,
      items: [
        {
          name: payload.service.service.name,
          id: payload.service.purchaseService.id,
          price: payload.service.purchaseService.value,
          brand: payload.talent.shortName,
          category: payload.service.service?.type,
          quantity: 1,
        },
      ],
    },
  })),
  [listTalents.SUCCESS]: trackEvent(({ payload }) => ({
    category: 'view_item_list',
    action: 'view_item_list',
    fieldsObject: {
      item_list_id: 'list_talents',
      item_list_name: 'List Talents',
      items: payload.items.map((item: any) => ({
        item_id: item.id,
        item_name: item.shortName,
        item_list_id: 'list_talents',
        item_list_name: 'List Talents',
        item_category: item?.categories[0]?.category?.name || '',
        item_category2: item?.categories[1]?.category?.name || '',
        item_category3: item?.categories[2]?.category?.name || '',
        item_category4: item?.categories[3]?.category?.name || '',
        item_category5: item?.categories[4]?.category?.name || '',
      })),
    },
  })),
};

export const gtagMiddleware = createMiddleware(eventsMap, ga);
