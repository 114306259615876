import { bindRoutineToReduxForm, createRoutine } from 'redux-saga-routines';

import {
  USER_RETRIEVE,
  USER_UPDATE,
  USER_MY_REQUESTS,
  USER_FORGOT_PASSWORD,
  USER_RESET_PASSWORD,
  USER_CHANGE_PASSWORD,
  USER_DELETE_ACCOUNT,
} from './types';

export const retrieveUser = createRoutine(USER_RETRIEVE);
export const updateUser = createRoutine(USER_UPDATE);
export const requestsUser = createRoutine(USER_MY_REQUESTS);
export const forgotPassword = createRoutine(USER_FORGOT_PASSWORD);
export const changePassword = createRoutine(USER_CHANGE_PASSWORD);
export const resetPassword = createRoutine(USER_RESET_PASSWORD);
export const deleteUserAccount = createRoutine(USER_DELETE_ACCOUNT);

export const bindedForgotPassword = bindRoutineToReduxForm(forgotPassword);
export const bindedChangePassword = bindRoutineToReduxForm(changePassword);
export const bindedResetPassword = bindRoutineToReduxForm(resetPassword);
export const bindedUpdateUser = bindRoutineToReduxForm(updateUser);
export const bindedDeleteUser = bindRoutineToReduxForm(deleteUserAccount);
