import produce, { Draft, Immutable } from 'immer';

import { submitCheckout } from './actions';

export type CheckoutState = Immutable<{
  loading: boolean;
  error: Error | string | null;
}>;

const initialState: CheckoutState = {
  loading: false,
  error: null,
};

const reducer = produce((draft: Draft<CheckoutState>, { type, payload }) => {
  switch (type) {
    case submitCheckout.TRIGGER:
      draft.loading = true;
      draft.error = null;
      break;
    case submitCheckout.FAILURE:
      draft.error = payload;
      break;
    case submitCheckout.FULFILL:
      draft.loading = false;
      break;
  }
}, initialState);

export default reducer;
