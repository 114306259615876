import { FC, PropsWithChildren } from 'react';
import Footer from '@views/components/footer';
import Navbar from '@views/components/navbar';
import CartSlideOut from '@views/components/cart-slideout';

const MainContainer: FC<PropsWithChildren<{}>> = ({ children }) => (
  <>
    <Navbar />
    <main>{children}</main>
    <Footer />
    <CartSlideOut />
  </>
);

export default MainContainer;
