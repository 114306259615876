import { FC, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import {
  Collapse,
  Label,
  Nav,
  NavItem,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
} from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  LazyLoadImage,
  trackWindowScroll,
  ScrollPosition,
} from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import { getCategoriesItems } from '@modules/general/selectors';
import LogoNav from '@assets/images/logo-nav.svg';
import classNames from 'classnames';
import { getAuthLoggedIn } from '@modules/auth/selectors';
import { authLogout } from '@modules/auth/actions';
import { getUserItem } from '@modules/user/selectors';

type NavSidebarProperties = {
  isOpen: boolean;
  toggle: () => void;
  scrollPosition: ScrollPosition;
};

const NavSidebar: FC<NavSidebarProperties> = ({
  isOpen,
  toggle,
  scrollPosition,
}) => {
  const dispatch = useDispatch();
  const categories = useSelector(getCategoriesItems);
  const isLoggedIn = useSelector(getAuthLoggedIn);
  const user = useSelector(getUserItem);

  const [isHelpOpen, setHelpOpen] = useState(false);

  const toggleHelp = () => setHelpOpen(!isHelpOpen);

  const logout = () => {
    dispatch(authLogout());
  };

  return (
    <Offcanvas
      className="sidebar ui__navbar"
      isOpen={isOpen}
      backdrop
      direction="end"
      scrollable
    >
      <OffcanvasHeader toggle={toggle}>
        <figure className="m-0 p-0">
          <LazyLoadImage
            src={LogoNav}
            alt="logo-nav"
            scrollPosition={scrollPosition}
          />
        </figure>
      </OffcanvasHeader>
      <OffcanvasBody>
        <Nav navbar className="w-100">
          {isLoggedIn && (
            <Label className="sidebar__label--user mb-5">
              {user?.firstName} {user?.lastName}
            </Label>
          )}
          <Label className="sidebar__label mb-4">Categorías</Label>
          {categories?.map((category) => (
            <NavItem className="mb-4" key={category.id}>
              <NavLink to={`/talents?category=${category.id}`}>
                {category.name}
              </NavLink>
            </NavItem>
          ))}
          {/* LOGGED IN */}
          {isLoggedIn && (
            <>
              <NavItem className="mb-4 mt-3">
                <NavLink to="/my-requests">
                  <i className="icon-ic_famousstar" />
                  Mis solicitudes
                </NavLink>
              </NavItem>
              <NavItem className="mb-4">
                <NavLink to="/settings" onClick={toggle}>
                  <i className="icon-ic_avatar" />
                  Mi perfil
                </NavLink>
              </NavItem>
            </>
          )}

          <NavItem className="mb-4 mt-3">
            <NavLink to="/booking" onClick={toggle}>
              <i className="icon-ic_famousstar_active" />
              Para tu empresa
            </NavLink>
          </NavItem>
          <NavItem
            className="mb-4 d-flex align-items-center"
            onClick={toggleHelp}
          >
            <i className="icon-ic_help" />
            Ayuda
            <i
              className={classNames('ms-auto icon-ic_right', {
                rotate: isHelpOpen,
              })}
            />
          </NavItem>
          <Collapse isOpen={isHelpOpen} className="ps-3">
            <NavItem className="mb-4">
              <a
                href="https://web.bestfriendfamoso.com/client-support/?source=client"
                target="_new"
              >
                <i className="icon-ic_help" />
                Chat en línea
              </a>
            </NavItem>
            <NavItem className="mb-4">
              <a href="mailto:soporte@bestfriendfamoso.com">
                <i className="icon-ic_mail" />
                Escríbenos
              </a>
            </NavItem>
            <NavItem className="mb-4">
              <a
                href="https://intercom.help/BestFriendFamoso/es/articles/6222073-preguntas-frequentes-fans"
                target="_new"
              >
                <i className="icon-ic_question1" />
                Preguntas frecuentes
              </a>
            </NavItem>
          </Collapse>

          {isLoggedIn && (
            <NavItem
              className="mb-4 d-flex align-items-center"
              onClick={logout}
            >
              <i className="icon-ic_sign" />
              Cerrar sesión
            </NavItem>
          )}
          {!isLoggedIn && (
            <NavItem className="mb-4 mt-3">
              <Link
                to="/auth"
                className="ui__button ui__button--navbar py-3 text-decoration-none w-100 d-block text-center"
              >
                Ingresar
              </Link>
            </NavItem>
          )}

          <NavItem className="mb-4 mt-3">
            <Link
              to="https://web.bestfriendfamoso.com/politica-privacidad/"
              className="sidebar__label text-decoration-none"
            >
              Políticas de privacidad
            </Link>
          </NavItem>
          <NavItem className="mb-4">
            <Link
              to="https://web.bestfriendfamoso.com/terminos-condiciones/"
              className="sidebar__label text-decoration-none"
            >
              Términos y condiciones
            </Link>
          </NavItem>
        </Nav>
      </OffcanvasBody>
    </Offcanvas>
  );
};

export default trackWindowScroll(NavSidebar);
