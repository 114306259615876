/* eslint-disable react/no-array-index-key */
import { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col, Button } from 'reactstrap';

import { getCartItems } from '@modules/cart/selector';
import { toggleCart } from '@modules/cart/actions';
import CartItem from '@views/ui/cart-item';
import CartDetail from '@views/ui/cart-detail';
import { useMediaQueries } from '@react-hook/media-query';
import { Link } from 'react-router-dom';
import CartEmtpy from './empty';

const Cart: FC = () => {
  const dispatch = useDispatch();
  const [selected, setSelected] = useState<any>(null);
  const items = useSelector(getCartItems);

  const total = items.reduce(
    (sum, item) => sum + (item?.service?.purchaseService?.value || 0),
    0,
  );

  const {
    matches: { isMobile = false },
  } = useMediaQueries({
    isMobile: 'only screen and (max-width: 580px)',
  });

  const handleGoToPayment = () => {
    dispatch(toggleCart());
  };

  const handleSelectPreview = (item: any) => () => {
    setSelected(item);
  };

  return (
    <section className="cart">
      <Container className="h-100">
        {items.length === 0 && <CartEmtpy />}
        {items.length > 0 && (
          <Row>
            {items.map((item, index) => (
              <Col xs={12} key={`cart-item-${index}`}>
                <CartItem
                  {...item}
                  index={index}
                  onClick={handleSelectPreview(item)}
                />
              </Col>
            ))}
          </Row>
        )}
        {selected && (
          <div className="cart__preview">
            <CartDetail {...selected} onGoBack={() => setSelected(null)} />
          </div>
        )}
      </Container>

      {items.length > 0 && (
        <Container className="cart__total py-4 px-3">
          <Row className="mb-3">
            <Col className="cart__total--title">Total</Col>
            <Col className="text-end">${total.toFixed(2)} USD</Col>
          </Row>
          <Row>
            <Col>
              {!isMobile ? (
                <Button
                  tag={Link}
                  onClick={handleGoToPayment}
                  to="/checkout"
                  className="ui__button ui__button--primary"
                >
                  Ir a pagar
                </Button>
              ) : (
                <Button
                  tag={Link}
                  to="/checkout"
                  className="ui__button ui__button--primary"
                >
                  Ir a pagar
                </Button>
              )}
            </Col>
          </Row>
        </Container>
      )}
    </section>
  );
};

export default Cart;
