import { Action, combineReducers, EmptyObject } from 'redux';
import { connectRouter, RouterState } from 'connected-react-router';
import { persistReducer } from 'redux-persist';
import { FormStateMap, reducer as formReducer } from 'redux-form';
import nav, { NavState } from '@modules/nav/reducer';
import auth, { AuthState } from '@modules/auth/reducer';
import user, { UserState } from '@modules/user/reducer';
import talent, { TalentState } from '@modules/talent/reducer';
import people, { PeopleState } from '@modules/people/reducer';
import location, { LocationState } from '@modules/location/reducer';
import general, { GeneralState } from '@modules/general/reducer';
import invoices, { InvoiceState } from '@modules/invoice/reducer';
import cart, { CartState } from '@modules/cart/reducer';
import checkout, { CheckoutState } from '@modules/checkout/reducer';
import booking, { BookingState } from '@modules/booking/reducer';

import { AUTH_CLEAR_SESSION } from '@modules/auth/types';
import { history } from './history';
import rootPersistConfig from './persistor';

export type ReducerType = {
  form: FormStateMap;
  router: RouterState;
  nav: NavState;
  auth: AuthState;
  user: UserState;
  talent: TalentState;
  people: PeopleState;
  location: LocationState;
  general: GeneralState;
  invoices: InvoiceState;
  cart: CartState;
  checkout: CheckoutState;
  booking: BookingState;
};

const appReducer = combineReducers<ReducerType | undefined>({
  // external
  router: connectRouter(history),
  form: formReducer,

  // app
  nav,
  auth,
  user,
  talent,
  people,
  location,
  general,
  invoices,
  cart,
  checkout,
  booking,
});

// Actions to empty the reducer. i.e LOGOUT
const actions: Set<string> = new Set([AUTH_CLEAR_SESSION]);

const rootReducer = (state: ReducerType | undefined, action: Action) => {
  if (actions.has(action.type)) {
    return appReducer({} as EmptyObject & ReducerType, action);
  }

  return appReducer(state, action);
};

export default persistReducer<ReducerType>(rootPersistConfig, rootReducer);
export { appReducer };
