/* eslint-disable import/prefer-default-export */
import { createRoutine } from 'redux-saga-routines';
import {
  RETRIEVE_INVOICE,
  CREATE_PENDING_INVOICE,
  UPDATE_INVOICE,
} from './types';

export const retrieveInvoice = createRoutine(RETRIEVE_INVOICE);
export const createPendingInvoice = createRoutine(CREATE_PENDING_INVOICE);
export const updateInvoice = createRoutine(UPDATE_INVOICE);
