import { Action } from 'redux-actions';
import { StrictEffect, call, put, takeLatest } from 'redux-saga/effects';
import SDK from '@utils/sdk';
import { CartItemType } from '@modules/cart/reducer';
import { CouponItem } from '@utils/sdk/types/api-types';
import {
  createPendingInvoice,
  retrieveInvoice,
  updateInvoice,
} from './actions';

export function* retrieveInvoiceSaga({
  payload,
}: Action<{ id: string }>): Generator<StrictEffect> {
  try {
    yield put(retrieveInvoice.request());
    const api: any = yield call([SDK, 'getApi']);
    const { body }: any = yield call([api.invoices, 'invoiceOne'], payload);
    yield put(retrieveInvoice.success(body));
  } catch (error) {
    yield put(retrieveInvoice.failure(error));
  } finally {
    yield put(retrieveInvoice.fulfill());
  }
}

export function* createPendingInvoiceSaga({
  payload: { cart, coupon },
}: Action<{
  cart: CartItemType[];
  coupon: CouponItem | null;
}>): Generator<StrictEffect> {
  try {
    yield put(createPendingInvoice.request());
    const details = cart.map((item) => ({
      ...item.invoice,
      count: 1,
      talentService: item.service.id,
      unitValue: item.service.purchaseService.value,
    }));

    const api: any = yield call([SDK, 'getApi']);
    const { body }: any = yield call(
      [api.invoices, 'invoiceCreatePending'],
      {},
      {
        requestBody: {
          details,
          currency: 'usd',
          coupon,
        },
      },
    );
    yield put(createPendingInvoice.success(body));
  } catch (error) {
    yield put(createPendingInvoice.failure(error));
  } finally {
    yield put(createPendingInvoice.fulfill());
  }
}

export function* updateInvoiceSaga({
  payload: { id, ...requestBody },
}: Action<{ id: string }>): Generator<StrictEffect> {
  try {
    yield put(updateInvoice.request());
    const api: any = yield call([SDK, 'getApi']);
    const { body }: any = yield call(
      [api.invoices, 'invoiceUpdate'],
      { id },
      { requestBody },
    );
    yield put(updateInvoice.success(body));
  } catch (error) {
    yield put(updateInvoice.failure(error));
  } finally {
    yield put(updateInvoice.fulfill());
  }
}

export default function* invoiceWatch() {
  yield takeLatest(retrieveInvoice.TRIGGER, retrieveInvoiceSaga);
  yield takeLatest(createPendingInvoice.TRIGGER, createPendingInvoiceSaga);
  yield takeLatest(updateInvoice.TRIGGER, updateInvoiceSaga);
}
