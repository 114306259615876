import {
  CategoryItem,
  IdentificationItem,
  OccassionItem,
  ServiceItem,
} from '@utils/sdk/types/api-types';
import produce, { Draft } from 'immer';
import {
  retrieveServices,
  retrieveCategoriesHighlights,
  retrieveCategories,
  retrieveOcassions,
  retrieveIdentificationTypes,
} from './actions';

interface GeneralInnerState<D extends {}> {
  items: D[];
  loading: boolean;
  error: any;
}

export type GeneralState = {
  services: GeneralInnerState<ServiceItem>;
  categories: GeneralInnerState<CategoryItem>;
  categoryHighlights: GeneralInnerState<CategoryItem>;
  occasions: GeneralInnerState<OccassionItem>;
  identificationTypes: GeneralInnerState<IdentificationItem>;
};

const initialState: GeneralState = {
  services: {
    loading: false,
    items: [],
    error: null,
  },
  categories: {
    loading: false,
    items: [],
    error: null,
  },
  categoryHighlights: {
    loading: false,
    items: [],
    error: null,
  },
  occasions: {
    loading: false,
    items: [],
    error: null,
  },
  identificationTypes: {
    loading: false,
    items: [],
    error: null,
  },
};

const reducer = produce((draft: Draft<GeneralState>, { type, payload }) => {
  switch (type) {
    // SERVICES
    case retrieveServices.TRIGGER:
      draft.services.loading = true;
      draft.services.error = null;
      break;
    case retrieveServices.SUCCESS:
      draft.services.items = payload;
      break;
    case retrieveServices.FAILURE:
      draft.services.error = payload;
      break;
    case retrieveServices.FULFILL:
      draft.services.loading = false;
      break;
    // SERVICES
    case retrieveCategories.TRIGGER:
      draft.categories.loading = true;
      draft.categories.error = null;
      break;
    case retrieveCategories.SUCCESS:
      draft.categories.items = payload;
      break;
    case retrieveCategories.FAILURE:
      draft.categories.error = payload;
      break;
    case retrieveCategories.FULFILL:
      draft.categories.loading = false;
      break;
    // OCCASIONS
    case retrieveOcassions.TRIGGER:
      draft.occasions.loading = true;
      draft.occasions.error = null;
      break;
    case retrieveOcassions.SUCCESS:
      draft.occasions.items = payload;
      break;
    case retrieveOcassions.FAILURE:
      draft.occasions.error = payload;
      break;
    case retrieveOcassions.FULFILL:
      draft.occasions.loading = false;
      break;
    // CATEGORY HIGHLIGHTS
    case retrieveCategoriesHighlights.TRIGGER:
      draft.categoryHighlights.loading = true;
      draft.categoryHighlights.error = null;
      break;
    case retrieveCategoriesHighlights.SUCCESS:
      draft.categoryHighlights.items = payload;
      break;
    case retrieveCategoriesHighlights.FAILURE:
      draft.categoryHighlights.error = payload;
      break;
    case retrieveCategoriesHighlights.FULFILL:
      draft.categoryHighlights.loading = false;
      break;

    // identificationTypes
    case retrieveIdentificationTypes.TRIGGER:
      draft.identificationTypes.loading = true;
      draft.identificationTypes.error = null;
      break;
    case retrieveIdentificationTypes.SUCCESS:
      draft.identificationTypes.items = payload;
      break;
    case retrieveIdentificationTypes.FAILURE:
      draft.identificationTypes.error = payload;
      break;
    case retrieveIdentificationTypes.FULFILL:
      draft.identificationTypes.loading = false;
      break;
  }
}, initialState);

export default reducer;
