import Loadable from 'react-loadable';
import ScreenLoading from '@views/ui/screen-loading';
import { locationCountries } from '@modules/location/actions';
import { authCheckPasswordCode } from '@modules/auth/actions';
import {
  retrieveCategories,
  retrieveCategoriesHighlights,
  retrieveIdentificationTypes,
  retrieveOcassions,
  retrieveServices,
} from '@modules/general/actions';
import {
  listTalents,
  retrieveTalent,
  retrieveTalentDedicationHighlights,
  retrieveTalentMultimedia,
} from '@modules/talent/actions';
import { requestsUser, retrieveUser } from '@modules/user/actions';
import { retrieveInvoice } from '@modules/invoice/actions';
import {
  retrieveBooking,
  retrieveBookingServices,
  retrieveBookingStatus,
} from '@modules/booking/actions';
import MainContainer from '@views/containers/main';
import RoutesType from './interface';

const Index = Loadable({
  loader: () => import('views/screens/home'),
  loading: ScreenLoading,
});
const LoginCheck = Loadable({
  loader: () => import('@views/screens/auth'),
  loading: ScreenLoading,
});
const Login = Loadable({
  loader: () => import('@views/screens/login'),
  loading: ScreenLoading,
});
const Register = Loadable({
  loader: () => import('@views/screens/register'),
  loading: ScreenLoading,
});
const RegisterCompletion = Loadable({
  loader: () => import('@views/screens/register-completion'),
  loading: ScreenLoading,
});
const ForgotPassword = Loadable({
  loader: () => import('@views/screens/forgot-password'),
  loading: ScreenLoading,
});
const RecoverPassword = Loadable({
  loader: () => import('@views/screens/recover-password'),
  loading: ScreenLoading,
});
const Settings = Loadable({
  loader: () => import('@views/screens/settings'),
  loading: ScreenLoading,
});
const SettingsDeleteAccount = Loadable({
  loader: () => import('@views/screens/settings-delete-account'),
  loading: ScreenLoading,
});
const Booking = Loadable({
  loader: () => import('@views/screens/booking'),
  loading: ScreenLoading,
});
const BookingSucess = Loadable({
  loader: () => import('@views/screens/booking-success'),
  loading: ScreenLoading,
});
const Talents = Loadable({
  loader: () => import('@views/screens/talents'),
  loading: ScreenLoading,
});

const TalentProfile = Loadable({
  loader: () => import('@views/screens/talent-profile'),
  loading: ScreenLoading,
});

const TalentService = Loadable({
  loader: () => import('@views/screens/talent-service'),
  loading: ScreenLoading,
});

const CartScreen = Loadable({
  loader: () => import('@views/screens/cart'),
  loading: ScreenLoading,
});

const Checkout = Loadable({
  loader: () => import('@views/screens/checkout'),
  loading: ScreenLoading,
});

const CheckoutSuccess = Loadable({
  loader: () => import('@views/screens/checkout/success'),
  loading: ScreenLoading,
});

const CheckoutProcessing = Loadable({
  loader: () => import('@views/screens/checkout/processed'),
  loading: ScreenLoading,
});

const CheckoutRejected = Loadable({
  loader: () => import('@views/screens/checkout/rejected'),
  loading: ScreenLoading,
});

const CheckoutRedirection = Loadable({
  loader: () => import('@views/screens/checkout/redirection'),
  loading: ScreenLoading,
});

const MyRequests = Loadable({
  loader: () => import('@views/screens/my-requests'),
  loading: ScreenLoading,
});

const RequestDetail = Loadable({
  loader: () => import('@views/screens/my-request'),
  loading: ScreenLoading,
});

const BookingDetail = Loadable({
  loader: () => import('@views/screens/my-booking'),
  loading: ScreenLoading,
});

const BookingLanding = Loadable({
  loader: () => import('@views/screens/booking-landing'),
  loading: ScreenLoading,
});

const routes: RoutesType[] = [
  {
    path: '/auth',
    component: LoginCheck,
    published: true,
    exact: true,
    redirectIsLogged: true,
  },
  {
    path: '/login',
    component: Login,
    published: true,
    exact: true,
    redirectIsLogged: true,
  },
  {
    path: '/register',
    component: Register,
    published: true,
    exact: true,
    redirectIsLogged: true,
  },
  {
    path: '/register-completion',
    component: RegisterCompletion,
    privated: true,
    exact: true,
    actions: [retrieveIdentificationTypes, locationCountries, retrieveUser],
  },
  {
    path: '/forgot-password',
    component: ForgotPassword,
    published: true,
    exact: true,
  },
  {
    path: '/recover-password',
    component: RecoverPassword,
    published: true,
    exact: true,
    actions: [authCheckPasswordCode],
  },
  {
    path: '/empresas',
    component: BookingLanding,
    privated: false,
    exact: true,
    actions: [retrieveCategoriesHighlights],
  },
  {
    path: '/',
    component: MainContainer,
    actions: [retrieveOcassions],
    routes: [
      {
        path: '/',
        component: Index,
        exact: true,
        published: true,
        actions: [
          listTalents,
          retrieveUser,
          requestsUser,
          retrieveServices,
          retrieveCategories,
          retrieveTalentDedicationHighlights,
          retrieveCategoriesHighlights,
        ],
      },
      {
        path: '/cart',
        component: CartScreen,
        published: true,
        exact: true,
      },
      {
        path: '/checkout',
        component: Checkout,
        published: true,
        exact: true,
        actions: [retrieveUser],
      },
      {
        path: '/checkout/redirection',
        component: CheckoutRedirection,
        privated: true,
        exact: true,
      },
      {
        path: '/checkout/success',
        component: CheckoutSuccess,
        privated: true,
        exact: true,
      },
      {
        path: '/checkout/processing',
        component: CheckoutProcessing,
        privated: true,
        exact: true,
      },
      {
        path: '/checkout/rejected',
        component: CheckoutRejected,
        published: true,
        exact: true,
      },
      {
        path: '/booking',
        component: Booking,
        published: true,
        exact: true,
        actions: [
          retrieveIdentificationTypes,
          locationCountries,
          retrieveBookingServices,
        ],
      },
      {
        path: '/booking/success',
        component: BookingSucess,
        published: true,
        exact: true,
      },
      {
        path: '/talents',
        component: Talents,
        privated: false,
        exact: true,
        defaultFilters: {
          category: undefined,
          name: undefined,
          nameOrder: 'ASC',
          limit: 12,
          page: 1,
        },
        actions: [listTalents, retrieveCategories, retrieveServices],
      },
      {
        path: '/talents/:id',
        component: TalentProfile,
        privated: false,
        exact: true,
        defaultFilters: {
          category: undefined,
          name: undefined,
          nameOrder: 'ASC',
          limit: 6,
          page: 1,
        },
        actions: [retrieveTalent, retrieveTalentMultimedia, listTalents],
      },
      {
        path: '/talents/:id/:service',
        component: TalentService,
        privated: false,
        exact: true,
        actions: [retrieveTalent, retrieveOcassions],
      },
      {
        path: '/my-requests',
        component: MyRequests,
        privated: true,
        exact: true,
        actions: [retrieveUser, requestsUser],
      },
      {
        path: '/my-requests/:id',
        component: RequestDetail,
        privated: true,
        exact: true,
        actions: [retrieveInvoice],
      },

      {
        path: '/my-booking/:id',
        component: BookingDetail,
        privated: true,
        exact: true,
        actions: [retrieveBookingStatus, retrieveBooking],
      },
      {
        path: '/settings',
        component: Settings,
        privated: true,
        exact: true,
        actions: [retrieveUser, retrieveIdentificationTypes, locationCountries],
      },
      {
        path: '/settings/delete-account',
        component: SettingsDeleteAccount,
        privated: true,
        exact: true,
        actions: [retrieveUser],
      },
      {
        name: '404',
        path: '*',
        component: () => null,
      },
    ],
  },
];

export default routes;
export { routes };
