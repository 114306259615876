import { FC } from 'react';
import { LoadingComponentProps } from 'react-loadable';
import Lottie from 'lottie-react';
import HandLottie from '@assets/lotties/bff-calculator-hand.json';
import CloudLottie from '@assets/lotties/bff-calculator-cloud.json';
import { Col, Row } from 'reactstrap';

const ScreenLoading: FC<LoadingComponentProps> = () => (
  <Row className="justify-content-center ui__loader ui__loader--wrapper align-items-center">
    <Col xs={4} className="mb-5 pb-5">
      <Lottie animationData={CloudLottie} />
      <Lottie animationData={HandLottie} className="ui__loader--lottie-hand " />
    </Col>
  </Row>
);

export default ScreenLoading;
