/* eslint-disable no-param-reassign */
import {
  StrictEffect,
  call,
  cancel,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';
import { SubmissionError } from 'redux-form';
import { Action } from 'redux-actions';
import { ReduxFormPayload } from 'redux-saga-routines';
import { push } from 'connected-react-router';

import SDK from '@utils/sdk';
import {
  authLogout,
  clearSession,
  debounceRefresh,
} from '@modules/auth/actions';
import { getAuthLoggedIn } from '@modules/auth/selectors';
import { getFilters } from '@modules/nav/selectors';
import {
  forgotPassword,
  resetPassword,
  changePassword,
  updateUser,
  retrieveUser,
  requestsUser,
  deleteUserAccount,
} from './actions';

import { getUserItem, getCodeForgetPassword } from './selectors';

export function* retrieveUserSaga({
  payload,
  type,
}: Action<{}>): Generator<StrictEffect> {
  try {
    const isLogged = yield select(getAuthLoggedIn);
    if (!isLogged) {
      yield cancel();
    }
    yield put(retrieveUser.request());
    const api: any = yield call([SDK, 'getApi']);
    const { body }: any = yield call([api.people, 'peopleMe']);
    yield put(retrieveUser.success(body));
  } catch (error) {
    yield put(retrieveUser.failure(error));
    yield put(debounceRefresh({ error, type, payload }));
  } finally {
    yield put(retrieveUser.fulfill());
  }
}

export function* requestsUserSaga({
  type,
  payload,
}: Action<{}>): Generator<StrictEffect> {
  try {
    const isLogged = yield select(getAuthLoggedIn);
    if (!isLogged) {
      yield cancel();
    }
    yield put(requestsUser.request());
    const api: any = yield call([SDK, 'getApi']);
    const { body }: any = yield call([api.people, 'peopleMyRequest']);
    yield put(requestsUser.success(body));
  } catch (error) {
    yield put(requestsUser.failure(error));
    yield put(debounceRefresh({ error, type, payload }));
  } finally {
    yield put(requestsUser.fulfill());
  }
}

export function* changePasswordSaga({
  type,
  payload,
  payload: {
    values: { password },
  },
}: Action<ReduxFormPayload<any, any>>): Generator<StrictEffect> {
  try {
    const { id }: any = yield select(getUserItem);
    yield put(changePassword.request());
    const requestBody = {
      userType: 'People',
      password,
    };
    const api: any = yield call([SDK, 'getApi']);
    yield call([api.auth, 'changePassword'], { id }, { requestBody });
    yield put(changePassword.success());
  } catch (error) {
    yield put(changePassword.failure(error));
    yield put(debounceRefresh({ error, type, payload }));
  } finally {
    yield put(changePassword.fulfill());
  }
}

export function* resetPasswordSaga({
  payload: {
    values: { password },
  },
}: Action<ReduxFormPayload<any, any>>): Generator<StrictEffect> {
  try {
    yield put(resetPassword.request());
    const requestBody = {
      userType: 'People',
      password,
    };
    const { email, token: code }: any = yield select(getCodeForgetPassword);
    const api: any = yield call([SDK, 'getApi']);
    yield call([api.auth, 'resetPassword'], { code, email }, { requestBody });
    yield put(resetPassword.success());
  } catch (error: any) {
    yield put(
      resetPassword.failure(
        new SubmissionError({ _error: error?.response?.body?.message }),
      ),
    );
  } finally {
    yield put(resetPassword.fulfill());
  }
}

export function* forgotPasswordSaga({
  payload: { values },
}: Action<ReduxFormPayload<any, any>>): Generator<StrictEffect> {
  try {
    yield put(forgotPassword.request());
    values.userType = 'People';
    const api: any = yield call([SDK, 'getApi']);
    yield call(
      [api.auth, 'forgotPassword'],
      { mobile: true },
      { requestBody: values },
    );
    yield put(forgotPassword.success());
  } catch (error) {
    yield put(forgotPassword.failure(error));
  } finally {
    yield put(forgotPassword.fulfill());
  }
}

export function* updateUserSaga({
  type,
  payload,
  payload: { values },
}: Action<ReduxFormPayload<any, any>>): Generator<StrictEffect> {
  try {
    yield put(updateUser.request());
    const urlParametres: any = yield select(getFilters);
    const { id }: any = yield select(getUserItem);
    const api: any = yield call([SDK, 'getApi']);
    const { phonenumber } = values;
    const requestBody = {
      ...values,
      ...(phonenumber && {
        phonenumber: phonenumber.phone,
        phoneCountryCode: phonenumber.country,
      }),
    };
    const { body }: any = yield call(
      [api.people, 'peopleUpdate'],
      { id },
      { requestBody },
    );
    yield put(updateUser.success(body));
    const redirect = urlParametres?.fromUrl ? urlParametres.fromUrl : '/';
    yield put(push(redirect));
  } catch (error) {
    yield put(updateUser.failure(error));
    yield put(debounceRefresh({ error, type, payload }));
  } finally {
    yield put(resetPassword.fulfill());
  }
}

export function* permanentlyDeleteSaga({
  payload: { values },
}: Action<ReduxFormPayload<any, any>>): Generator<StrictEffect> {
  try {
    const requestBody = { ...values, type: 'People' };
    const { id }: any = yield select(getUserItem);
    yield put(deleteUserAccount.request());
    const api: any = yield call([SDK, 'getApi']);
    yield call(
      [api.people, 'peoplePermanentlyDelete'],
      { id },
      { requestBody },
    );
    yield put(deleteUserAccount.success());
    yield put(authLogout.success());
    yield call([SDK, 'setToken'], null);
    yield put(clearSession());
  } catch (error) {
    yield put(deleteUserAccount.failure(error));
  } finally {
    yield put(deleteUserAccount.fulfill());
  }
}

export default function* meWatch() {
  yield takeLatest(requestsUser.TRIGGER, requestsUserSaga);
  yield takeLatest(changePassword.TRIGGER, changePasswordSaga);
  yield takeLatest(updateUser.TRIGGER, updateUserSaga);
  yield takeLatest(retrieveUser.TRIGGER, retrieveUserSaga);
  yield takeLatest(forgotPassword.TRIGGER, forgotPasswordSaga);
  yield takeLatest(resetPassword.TRIGGER, resetPasswordSaga);
  yield takeLatest(deleteUserAccount.TRIGGER, permanentlyDeleteSaga);
}
